import React, { useEffect, useState } from "react";
import {
  Typography,
  // Select,
  // Table,
  Row,
  Col,
  // Dropdown,
  // Space,
  Tag,
  Tooltip,
  Avatar,
  message
  // Menu,
} from "antd";
import dayjs from "dayjs";
import "./RFQList.css";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  colorMapping,
  dateFormat,
  handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyGetRFQListExcelQuery,
  useLazyGetRFQListQuery,
} from "../../../apis/rfqList";
import { useDispatch, useSelector } from "react-redux";
import { getRFQList } from "../../../redux/slices/rfqList/action";
import { rfqListSelector } from "../../../redux/slices/rfqList/selector";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { LayoutWrapper } from "../../../components/LayoutWrapper/LayoutWrapper";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { saveAs } from "file-saver";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
import {getNameInitials} from "../../../commonFuntions/CommonUtilFunctions";

const { Title, Text } = Typography;

const RFQList = () => {
  // const [, setSearchText] = useState("");
  const [dateFilter, setDateFilter] = useState();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState({});
  const [rfqStatusFilter, setRfqStatusFilter] = useState();
  const [rfqReportingFilter, setRfqReportingFilter] = useState();


  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  const [sorterState, setSorterState] = useState({
    rfq_id: "",
    created_at: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reportingManagers,setReportingManagers] = useState([])

  const rfqListArray = useSelector(rfqListSelector);
  // const { data: apiData } = useGetRFQListQuery();
  const [rfqListApi, { data: apiData, isFetching: isLoading }] =
    useLazyGetRFQListQuery();
  const [
    excelApi,
    {
      isFetching: exlLoading,
      //  data: excelApiData
    },
  ] = useLazyGetRFQListExcelQuery();
  useEffect(() => {
  
    if (apiData) {
      setReportingManagers(apiData?.data?.reporting_managers)
      dispatch(getRFQList(apiData?.data?.results));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);
  useEffect(() => {
    rfqListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfqListArray) {
      let arr = [];
      rfqListArray.map((item, index) => {
        var reporting_manager = null
        if(item.reporting_manager){
          reporting_manager = item.reporting_manager.reporting_person
        }
        let obj = {
          key: index,
          id: item.rfq_id,
          date: item.created_at,
          name: item.rfq_created_by.full_name,
          product: item.product_details.mob_sku,
          mobileNo: item.rfq_created_by.phone_number,
          email: item.rfq_created_by.email,
          remarks: item.rfq_remarks,
          totalQuotes: item.rfq_quotes.length,
          status: item.rfq_status,
          orderNo: item.orderNo,
          view: item.rfq_file,
          order_details: item.order_details,
          is_redundant : item.is_redundant,
          reporting_manager : reporting_manager
        };
       
        return arr.push(obj);
      });
      setTableData(arr);
    }
  }, [rfqListArray]);

  const handleSearchedValue = (params) => {
    console.log(params)

    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleNavigateToRFQDetails = (id) => {
    navigate(`/admin/rfq/quotes/${id}`, { state: { id } });
  };

  const options = [
    {
      key: 1,
      value: "Today",
      label: "Today",
    },
    {
      key: 2,
      value: "Yesterday",
      label: "Yesterday",
    },
    {
      key: 3,
      value: "last_7_day",
      label: "Last 7 day",
    },
    {
      key: 4,
      value: "last_30_day",
      label: "Last 30 day",
    },
    {
      key: 5,
      value: "last_60_day",
      label: "Last 60 day",
    },
    {
      key: 6,
      value: "Custom",
      label: "Custom",
    },
    {
      key: 7,
      value: "clear_date",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
      ),
    },
  ];
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    rfqListApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
  const columns = [
 

    {
      // title: "RFQ Id",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">RFQ Id</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.rfq_id === "rfq_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("rfq_id")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color: sorterState?.rfq_id === "-rfq_id" ? "" : "grey",
                }}
                onClick={() => sorterChange("-rfq_id")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "id",
      width: "200px",
      className: "rfq-id-class",
      fixed: "left",

      render: (params) => {
        return (
          <>
            <Link
              to={`/admin/rfq/quotes/${params}`}
              target="_blank"
              // onClick={() => handleNavigateToRFQDetails(params)}
            >
              {params}
            </Link>
            {/* <Col onClick={() => handleNavigateToRFQDetails(params)}>
              {params}
            </Col> */}
          </>
        );
      },

      // sorter: (a, b) => a.id.localeCompare(b.id),
      // sortDirections: ["ascend", "descend"]  ,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (params, record) => {
        if (record.is_redundant) {
          params = 'Redundant/Lost';
        }
        return (
          <Tag
            style={{ padding: "8px" }}
            className={`tag-font-style ${colorMapping[params]}`}
          >
            {params}
          </Tag>
        );
      },
      width: "150px",
    },
    {
      // title: "Date",
      title: (
        <>
          <Col className="title_sorter">
            <Text className="sorter_heading">Date</Text>
            <Text className="sorter_container_icon">
              <CaretUpOutlined
                style={{
                  color: sorterState?.created_at === "created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("created_at")}
                className="sorter_icon"
              />
              <CaretDownOutlined
                style={{
                  color:
                    sorterState?.created_at === "-created_at" ? "" : "grey",
                }}
                onClick={() => sorterChange("-created_at")}
                className="sorter_icon"
              />
            </Text>
          </Col>
        </>
      ),
      dataIndex: "date",
      render: (params) => {
        return <Col>{dateFormat(params)}</Col>;
      },
      width: "200px",
    },
    {
      title: "Assigned To",
      dataIndex: "reporting_manager",
      width: "220px",
      className: "rfq-id-class",
      render: (params, record) => {
      
        return (
          <>

      {params === null ? (
        <Text type="danger">Not assigned</Text>
      ) : (
        <div>
         <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px',marginTop:'8px' }}>
            {getNameInitials(params)}
          </Avatar>
           <Text type="">{params}
           </Text>
        </div>
      )}

         
           
          </>
        );
      },
    },

    {
      title: "Product",
      dataIndex: "product",
      width: "150px",
      className: "rfq-id-class",
      render: (params, record) => {
        return (
          <>
            {params && (
              <Col onClick={() => handleNavigateToRFQDetails(record?.id)}>
                {params ? `SKU: ${params}` : ""}
              </Col>
            )}
            {record?.view && (
              <Col
                style={{ cursor: "pointer" }}
                onClick={() => handlePdfDownload(record?.view)}
              >
                {record?.view && <FileIcon />} View
              </Col>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "350px",
    },
    {
      title: "Business mobile",
      dataIndex: "mobileNo",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "350px",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "300px",
      render: (params) => {
        return (
          <>
            <Tooltip title={params} className="rfq-table-remarks" color="#fff">
              <Text style={{ cursor: "pointer" }}>
                {params?.length > 100
                  ? `${params?.slice(0, 60)}...`
                  : params || ""}
              </Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Total quotes",
      dataIndex: "totalQuotes",
      align: "center",
      width: "150px",
    },
    {
      title: "Order No",
      dataIndex: "orderNo",
      width: "180px",
      render: (params, record) => {
        return (
          <>
            <Link
              to={`/admin/orders/order-details/${record?.order_details?.id}`}
              // onClick={() => handleNavigateToRFQDetails(params)}
            >
              {record?.order_details?.order_id || "N/A"}
            </Link>
            {/* <Col onClick={() => handleNavigateToRFQDetails(params)}>
              {params}
            </Col> */}
          </>
        );
      },
    },
   
  ];

  const handleStatusChange = (value)=>{
    setCurrentPage(1);

    if (value === "clear"){
      const params = {
        status: "",
        ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
        ...(currentPage && { page: 1 }),
        ...(searchedValue && { search: searchedValue?.search }),
        ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
        ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
        ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      };

      rfqListApi(params)
      setRfqStatusFilter()
      return
    }

   const params = {
    status: value,
    ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
    ...(currentPage && { page: 1 }),
    ...(searchedValue && { search: searchedValue?.search }),
    ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
    ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
    ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
    ...(sorterState.created_at && { sort_by: sorterState.created_at }),
  };

  setRfqStatusFilter(value)
  rfqListApi(params);
    setRfqStatusFilter(value)
  }
  const handleReportingChange = (value)=>{
    setCurrentPage(1);

    if (value === "clear"){
      rfqListApi({
          reporting_manager: "",
          ...(rfqStatusFilter && {status: rfqStatusFilter}),
          ...(currentPage && { page: 1 }),
          ...(searchedValue && { search: searchedValue?.search }),
          ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
          ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
          ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      })
      setRfqReportingFilter()
      return
    }

    const params = {
      reporting_manager: value,
      ...(rfqStatusFilter && {status: rfqStatusFilter}),
      ...(currentPage && { page: 1 }),
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    
    rfqListApi(params);
    setRfqReportingFilter(value)
  }

  
 
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        rfqListApi({
          status: value,
          ...(rfqStatusFilter && {status: rfqStatusFilter}),
          ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
          ...(currentPage && { page: 1 }),
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        rfqListApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(rfqStatusFilter && {status: rfqStatusFilter}),
          ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      rfqListApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(rfqStatusFilter && {status: rfqStatusFilter}),
        ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
        ...(searchedValue?.search && { search: searchedValue?.search }),
        ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };


  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(rfqStatusFilter && {status: rfqStatusFilter}),
      ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await rfqListApi(params);
  };

  function convertToExportOptions(data) {
    var options =  data.map(item => ({
      key: item.id,
      value: item.id,
      label: item.reporting_person,
      
    }));
    options.push( {
      key: 9999,
      value: "clear",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear</Col>
      ),
    },)
    return options
  }

  const exportOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];

  const rfqStatusOptions = [
    {
      key: 1,
      value: "Requested",
      label: "Requested",
    },
    {
      key: 2,
      value: "Quote sent",
      label: "Quote sent",
    },
    {
      key: 3,
      value: "Quote Accepted",
      label: "Quote Accepted",
    },
    {
      key: 5,
      value: "Order Created",
      label: "Order Created",
    },
    {
      key: 6,
      value: "Redundant/Lost",
      label: "Redundant/Lost",
    },
    {
      key: 7,
      value: "clear",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear</Col>
      ),
    },
  ]

  const handleExportApi = async () => {
    let response = await excelApi({
      ...(rfqReportingFilter && {reporting_manager: rfqReportingFilter}),
      ...(rfqStatusFilter && {status: rfqStatusFilter}),
      ...(searchedValue.search && { search: searchedValue.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      ...(sorterState.created_at && { sort_by: sorterState.created_at }),

    });
    if(response?.data?.status === false){
      message.error(`${response?.data?.message}`);
      return;
    }
    if (response?.status === "fulfilled") {
      saveAs(response?.data?.data?.url);
    }


  };
  return (
    <LayoutWrapper>
      <Title
        level={4}
        style={{
          margin: "0 0  32px 0",
          color: "#0a243f",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
        }}
      >
        RFQ'S
      </Title>
      <Row style={{ marginBottom: "20px" }} className="action-container">
        <Col className="action-sub-container">
          <SearchInput
            size={"medium"}
            placeholder={"Search customer name,mobile.no,email"}
            width={"400px"}
            getLibraryApi={rfqListApi}
            handleSearchedValue={handleSearchedValue}
            isLoading={isLoading}
            queryparams={{
              'status' : rfqStatusFilter,
              'reporting_manager' : rfqReportingFilter,
              'page' : 1,
              'start_date' : dateFilter?.start_date,
              'end_date' : dateFilter?.end_date
            }}
          />

          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={options}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}

        
            <CustomSelect
            placeholder={"Status"}
            width={"150px"}
            options={rfqStatusOptions}
            handleChange={handleStatusChange}
            value={rfqStatusFilter}
          />


        <CustomSelect
            placeholder={"Assigned to"}
            width={"150px"}
            options={convertToExportOptions(reportingManagers)}
            handleChange={handleReportingChange}
            value={rfqReportingFilter}

          />

    <CustomSelect
            placeholder={"Export"}
            width={"150px"}
            options={exportOptions}
            handleChange={handleExportApi}
            value={exlLoading ? <LoadingOutlined /> : "Export"}
            disabled={exlLoading ? true : false}
            loading={exlLoading}
          />


        </Col>
      </Row>

    
      <CustomTableWithPagination
        className="rfq-list-table"
        tableDataSource={tableData || []}
        tableColumns={columns}
        locale={{
          emptyText: isLoading ? (
            <TableSkeleton length={30} />
          ) : (
            <NoDataFound text={"No RFQ'S found!"} />
          ),
        }}
        scroll={{ x: "100%" }}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={!isLoading && apiData?.data?.pagination?.total_entries}
        isLoading={tableData.length && isLoading}
      />
    </LayoutWrapper>
  );
};

export default RFQList;
