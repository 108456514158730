// -------------live url
export const apiBaseUrl =
  `https://mob.madoverbuilding.com` || process.env.REACT_APP_BASE_URL;

export const demandRupifiUrl = `https://madoverbuildings.com/home/checkout?checkout_url=`;
export const showDemandRupifiUrl = `https://madoverbuildings.com`;

// -------------local url
// export const apiBaseUrl = `https://uat.madoverbuilding.com`;
export const baseUrl = ``;

// export const demandRupifiUrl = `https://mob-demand-side.netlify.app/home/checkout?checkout_url=`;
// export const showDemandRupifiUrl = `https://mob-demand-side.netlify.app`;

export const gstRegex = /^(?:[0-3|9][0-9])[a-zA-Z0-9]{13}$/;
// export const gstRegex = /^[0-9]{2}[a-zA-z0-9]{13}$/;
export const rupifiStatusCheck = ["ACTIVE", "REJECTED","INACTIVE","PRE_APPROVED","INCOMPLETE","UNDER_REVIEW"];
export const genderOption = [
  {
    key: 1,
    label: "Male",
    value: "Male",
  },
  {
    key: 2,
    label: "Female",
    value: "Female",
  },
  {
    key: 3,
    label: "Others",
    value: "Others",
  },
];
export const serviceRequestStatusOption = [
  {
    key: 1,
    label: "Registered",
    value: "Registered",
  },
  {
    key: 2,
    label: "In Progress",
    value: "In Progress",
  },
  {
    key: 3,
    label: "Resolved",
    value: "Resolved",
  },
  {
    key: 4,
    label: "Redundant",
    value: "Redundant",
  },
];
export const issueTypeOptions = [
  {key: "1", value: "Damaged Product", label: "Damaged Product"},
  {key: "2", value: "Quantity Issue", label: "Quantity Issue"},
  {key: "3", value: "Wrong Material Sent", label: "Wrong Material Sent"},
  {
    key: "4",
    value: "Under Warranty Service Requests",
    label: "Under Warranty Service Requests",
  },
  {
    key: "5",
    value: "Installation/Working Issue",
    label: "Installation/Working Issue",
  },
  {key: "6", value: "Return Request", label: "Return Request"},
  {key: "7", value: "Invoice Amount Issue", label: "Invoice Amount Issue"},
  {key: "8", value: "Payment Capture Issue", label: "Payment Capture Issue"},
  {key: "9", value: "Replacement Request", label: "Replacement Request"},
  {key: "10", value: "Other", label: "Other"},
];

export const amountType = [
  {
    key: 1,
    label: "Add",
    value: "CREDIT",
  },
  {
    key: 2,
    label: "Deduct",
    value: "DEBIT",
  },
];
export const cityOptions = [
  { key: 1, label: "Delhi", value: "Delhi" },
  { key: 2, label: "Mumbai", value: "Mumbai" },
  { key: 3, label: "Kolkata", value: "Kolkata" },
  { key: 4, label: "Bengaluru", value: "Bengaluru" },
  { key: 5, label: "Chennai", value: "Chennai" },
  { key: 6, label: "Hyderabad", value: "Hyderabad" },
  { key: 7, label: "Pune", value: "Pune" },
  { key: 8, label: "Ahmedabad", value: "Ahmedabad" },
  { key: 9, label: "Surat", value: "Surat" },
  { key: 10, label: "Lucknow", value: "Lucknow" },
  { key: 11, label: "Jaipur", value: "Jaipur" },
  { key: 12, label: "Kanpur", value: "Kanpur" },
  { key: 13, label: "Mirzapur", value: "Mirzapur" },
  { key: 14, label: "Nagpur", value: "Nagpur" },
  { key: 15, label: "Ghaziabad", value: "Ghaziabad" },
  { key: 16, label: "Supaul", value: "Supaul" },
  { key: 17, label: "Vadodara", value: "Vadodara" },
  { key: 18, label: "Rajkot", value: "Rajkot" },
  { key: 19, label: "Visakhapatnam", value: "Visakhapatnam" },
  { key: 20, label: "Indore", value: "Indore" },
  { key: 21, label: "Thane", value: "Thane" },
  { key: 22, label: "Bhopal", value: "Bhopal" },
  { key: 23, label: "Pimpri-Chinchwad", value: "Pimpri-Chinchwad" },
  { key: 24, label: "Patna", value: "Patna" },
  { key: 25, label: "Bilaspur", value: "Bilaspur" },
  { key: 26, label: "Ludhiana", value: "Ludhiana" },
  { key: 27, label: "Agra", value: "Agra" },
  { key: 28, label: "Madurai", value: "Madurai" },
  { key: 29, label: "Jamshedpur", value: "Jamshedpur" },
  { key: 30, label: "Prayagraj", value: "Prayagraj" },
  { key: 31, label: "Nashik", value: "Nashik" },
  { key: 32, label: "Faridabad", value: "Faridabad" },
  { key: 33, label: "Meerut", value: "Meerut" },
  { key: 34, label: "Jabalpur", value: "Jabalpur" },
  { key: 35, label: "Kalyan", value: "Kalyan" },
  { key: 36, label: "Vasai-Virar", value: "Vasai-Virar" },
  { key: 37, label: "Najafgarh", value: "Najafgarh" },
  { key: 38, label: "Varanasi", value: "Varanasi" },
  { key: 39, label: "Srinagar", value: "Srinagar" },
  { key: 40, label: "Aurangabad", value: "Aurangabad" },
  { key: 41, label: "Dhanbad", value: "Dhanbad" },
  { key: 42, label: "Amritsar", value: "Amritsar" },
  { key: 43, label: "Aligarh", value: "Aligarh" },
  { key: 44, label: "Guwahati", value: "Guwahati" },
  { key: 45, label: "Howrah", value: "Howrah" },
  { key: 46, label: "Ranchi", value: "Ranchi" },
  { key: 47, label: "Gwalior", value: "Gwalior" },
  { key: 48, label: "Chandigarh", value: "Chandigarh" },
  { key: 49, label: "Haldwani", value: "Haldwani" },
  { key: 50, label: "Vijayawada", value: "Vijayawada" },
  { key: 51, label: "Jodhpur", value: "Jodhpur" },
  { key: 52, label: "Raipur", value: "Raipur" },
  { key: 53, label: "Kota", value: "Kota" },
  { key: 54, label: "Bhayandar", value: "Bhayandar" },
  { key: 55, label: "Loni", value: "Loni" },
  { key: 56, label: "Ambattur", value: "Ambattur" },
  { key: 57, label: "Salt Lake City", value: "Salt Lake City" },
  { key: 58, label: "Bhatpara", value: "Bhatpara" },
  { key: 59, label: "Kukatpalli", value: "Kukatpalli" },
  { key: 60, label: "Dasarhalli", value: "Dasarhalli" },
  { key: 61, label: "Muzaffarpur", value: "Muzaffarpur" },
  { key: 62, label: "Oulgaret", value: "Oulgaret" },
  { key: 63, label: "New Delhi", value: "New Delhi" },
  { key: 64, label: "Tiruvottiyur", value: "Tiruvottiyur" },
  { key: 65, label: "Puducherry", value: "Puducherry" },
  { key: 66, label: "Byatarayanpur", value: "Byatarayanpur" },
  { key: 67, label: "Pallavaram", value: "Pallavaram" },
  { key: 68, label: "Secunderabad", value: "Secunderabad" },
  { key: 69, label: "Shimla", value: "Shimla" },
  { key: 70, label: "Puri", value: "Puri" },
  { key: 71, label: "Murtazabad", value: "Murtazabad" },
  { key: 72, label: "Shrirampur", value: "Shrirampur" },
  { key: 73, label: "Chandannagar", value: "Chandannagar" },
  { key: 74, label: "Sultampur Mazra", value: "Sultampur Mazra" },
  { key: 75, label: "Krishnanagar", value: "Krishnanagar" },
  { key: 76, label: "Barakpur", value: "Barakpur" },
  { key: 77, label: "Bhalaswa Jahangirpur", value: "Bhalaswa Jahangirpur" },
  { key: 78, label: "Nangloi Jat", value: "Nangloi Jat" },
  { key: 79, label: "Balasore", value: "Balasore" },
  { key: 80, label: "Dalupura", value: "Dalupura" },
  { key: 81, label: "Yelahanka", value: "Yelahanka" },
  { key: 82, label: "Titagarh", value: "Titagarh" },
  { key: 83, label: "Dam Dam", value: "Dam Dam" },
  { key: 84, label: "Bansbaria", value: "Bansbaria" },
  { key: 85, label: "Madhavaram", value: "Madhavaram" },
  { key: 86, label: "Abbigeri", value: "Abbigeri" },
  { key: 87, label: "Baj Baj", value: "Baj Baj" },
  { key: 88, label: "Garhi", value: "Garhi" },
  { key: 89, label: "Mirpeta", value: "Mirpeta" },
  { key: 90, label: "Nerkunram", value: "Nerkunram" },
  { key: 91, label: "Kendrapara", value: "Kendrapara" },
  { key: 92, label: "Sijua", value: "Sijua" },
  { key: 93, label: "Manali", value: "Manali" },
  { key: 94, label: "Kankuria", value: "Kankuria" },
  { key: 95, label: "Chakapara", value: "Chakapara" },
  { key: 96, label: "Pappakurichchi", value: "Pappakurichchi" },
  { key: 97, label: "Herohalli", value: "Herohalli" },
  { key: 98, label: "Madipakkam", value: "Madipakkam" },
  { key: 99, label: "Sabalpur", value: "Sabalpur" },
  { key: 100, label: "Bauria", value: "Bauria" },
  { key: 101, label: "Salua", value: "Salua" },
  { key: 102, label: "Chik Bnavar", value: "Chik Bnavar" },
  { key: 103, label: "Jalhalli", value: "Jalhalli" },
  { key: 104, label: "Chinnasekkadu", value: "Chinnasekkadu" },
  { key: 105, label: "Jethuli", value: "Jethuli" },
  { key: 106, label: "Nagtala", value: "Nagtala" },
  { key: 107, label: "Pakri", value: "Pakri" },
  { key: 108, label: "Hunasamaranhalli", value: "Hunasamaranhalli" },
  { key: 109, label: "Hesarghatta", value: "Hesarghatta" },
  { key: 110, label: "Bommayapalaiyam", value: "Bommayapalaiyam" },
  { key: 111, label: "Gundur", value: "Gundur" },
  { key: 112, label: "Punadih", value: "Punadih" },
  { key: 113, label: "Hariladih", value: "Hariladih" },
  { key: 114, label: "Alawalpur", value: "Alawalpur" },
  { key: 115, label: "Madnaikanhalli", value: "Madnaikanhalli" },
  { key: 116, label: "Bagalur", value: "Bagalur" },
  { key: 117, label: "Kadiganahalli", value: "Kadiganahalli" },
  { key: 118, label: "Khanpur Zabti", value: "Khanpur Zabti" },
  { key: 119, label: "Mahuli", value: "Mahuli" },
  { key: 120, label: "Zeyadah Kot", value: "Zeyadah Kot" },
  { key: 121, label: "Arshakunti", value: "Arshakunti" },
  { key: 122, label: "Mirchi", value: "Mirchi" },
  { key: 123, label: "Sonudih", value: "Sonudih" },
  { key: 124, label: "Bayandhalli", value: "Bayandhalli" },
  { key: 125, label: "Sondekoppa", value: "Sondekoppa" },
  { key: 126, label: "Babura", value: "Babura" },
  { key: 127, label: "Madavar", value: "Madavar" },
  { key: 128, label: "Kadabgeri", value: "Kadabgeri" },
  { key: 129, label: "Nanmangalam", value: "Nanmangalam" },
  { key: 130, label: "Taliganja", value: "Taliganja" },
  { key: 131, label: "Tarchha", value: "Tarchha" },
  { key: 132, label: "Belgharia", value: "Belgharia" },
  { key: 133, label: "Kammanhalli", value: "Kammanhalli" },
  { key: 134, label: "Ambapuram", value: "Ambapuram" },
  { key: 135, label: "Sonnappanhalli", value: "Sonnappanhalli" },
  { key: 136, label: "Kedihati", value: "Kedihati" },
  { key: 137, label: "Doddajivanahalli", value: "Doddajivanahalli" },
  { key: 138, label: "Simli Murarpur", value: "Simli Murarpur" },
  { key: 139, label: "Sonawan", value: "Sonawan" },
  { key: 140, label: "Devanandapur", value: "Devanandapur" },
  { key: 141, label: "Tribeni", value: "Tribeni" },
  { key: 142, label: "Huttanhalli", value: "Huttanhalli" },
  { key: 143, label: "Nathupur", value: "Nathupur" },
  { key: 144, label: "Bali", value: "Bali" },
  { key: 145, label: "Vajarhalli", value: "Vajarhalli" },
  { key: 146, label: "Alija Kotla", value: "Alija Kotla" },
  { key: 147, label: "Saino", value: "Saino" },
  { key: 148, label: "Shekhpura", value: "Shekhpura" },
  { key: 149, label: "Cachoha", value: "Cachoha" },
  { key: 150, label: "Andheri", value: "Andheri" },
  { key: 151, label: "Narayanpur Kola", value: "Narayanpur Kola" },
  { key: 152, label: "Gyan Chak", value: "Gyan Chak" },
  { key: 153, label: "Kasgatpur", value: "Kasgatpur" },
  { key: 154, label: "Kitanelli", value: "Kitanelli" },
  { key: 155, label: "Harchandi", value: "Harchandi" },
  { key: 156, label: "Santoshpur", value: "Santoshpur" },
  { key: 157, label: "Bendravadi", value: "Bendravadi" },
  { key: 158, label: "Kodagihalli", value: "Kodagihalli" },
  { key: 159, label: "Harna Buzurg", value: "Harna Buzurg" },
  { key: 160, label: "Mailanhalli", value: "Mailanhalli" },
  { key: 161, label: "Sultampur", value: "Sultampur" },
  { key: 162, label: "Adakimaranhalli", value: "Adakimaranhalli" },
  { key: 163, label: "Delhi NCR", value: "Delhi NCR" },
];
export const stateOptions = [
  {
    key: 1,
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {key: 2, label: "Andhra Pradesh", value: "Andhra Pradesh"},
  {key: 3, label: "Arunachal Pradesh", value: "Arunachal Pradesh"},
  {key: 4, label: "Assam", value: "Assam"},
  {key: 5, label: "Bihar", value: "Bihar"},
  {key: 6, label: "Chandigarh", value: "Chandigarh"},
  {key: 7, label: "Chhattisgarh", value: "Chhattisgarh"},
  {
    key: 8,
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {key: 9, label: "Delhi", value: "Delhi"},
  {key: 10, label: "Goa", value: "Goa"},
  {key: 11, label: "Gujarat", value: "Gujarat"},
  {key: 12, label: "Haryana", value: "Haryana"},
  {key: 13, label: "Himachal Pradesh", value: "Himachal Pradesh"},
  {key: 14, label: "Hyderabad", value: "Hyderabad"},
  {key: 15, label: "Jammu and Kashmir", value: "Jammu and Kashmir"},
  {key: 16, label: "Jharkhand", value: "Jharkhand"},
  {key: 17, label: "Karnataka", value: "Karnataka"},
  {key: 18, label: "Kerala", value: "Kerala"},
  {key: 19, label: "Ladakh", value: "Ladakh"},
  {key: 20, label: "Lakshadweep", value: "Lakshadweep"},
  {key: 21, label: "Madhya Pradesh", value: "Madhya Pradesh"},
  {key: 22, label: "Maharashtra", value: "Maharashtra"},
  {key: 23, label: "Manipur", value: "Manipur"},
  {key: 24, label: "Meghalaya", value: "Meghalaya"},
  {key: 25, label: "Mizoram", value: "Mizoram"},
  {key: 26, label: "Nagaland", value: "Nagaland"},
  {key: 27, label: "Odisha", value: "Odisha"},
  {key: 28, label: "Puducherry", value: "Puducherry"},
  {key: 29, label: "Punjab", value: "Punjab"},
  {key: 30, label: "Rajasthan", value: "Rajasthan"},
  {key: 31, label: "Sikkim", value: "Sikkim"},
  {key: 32, label: "Tamil Nadu", value: "Tamil Nadu"},
  {key: 33, label: "Telangana", value: "Telangana"},
  {key: 34, label: "Tripura", value: "Tripura"},
  {key: 35, label: "Uttar Pradesh", value: "Uttar Pradesh"},
  {key: 36, label: "Uttarakhand", value: "Uttarakhand"},
  {key: 37, label: "West Bengal", value: "West Bengal"},
];

export const dropDownData = {
  primary_material_type: [
    {key: "bamboo", label: "Bamboo", value: "Bamboo"},
    {key: "ceramic", label: "Ceramic", value: "Ceramic"},
    {key: "crystal", label: "Crystal", value: "Crystal"},
    {key: "faux_wood", label: "Faux Wood", value: "Faux Wood"},
    {key: "glass", label: "Glass", value: "Glass"},
    {key: "metal", label: "Metal", value: "Metal"},
    {key: "plastic", label: "Plastic", value: "Plastic"},
    {key: "resin", label: "Resin", value: "Resin"},
    {key: "wood", label: "Wood", value: "Wood"},
    {key: "abs", label: "ABS", value: "ABS"},
    {key: "aluminum", label: "Aluminum", value: "Aluminum"},
    {key: "aluminum_oxide", label: "Aluminum Oxide", value: "Aluminum Oxide"},
    {key: "antimony", label: "Antimony", value: "Antimony"},
    {key: "baking_soda", label: "Baking Soda", value: "Baking Soda"},
    {key: "bismuth", label: "Bismuth", value: "Bismuth"},
    {key: "black_oxide", label: "Black Oxide", value: "Black Oxide"},
    {key: "brass", label: "Brass", value: "Brass"},
    {key: "bronze", label: "Bronze", value: "Bronze"},
    {key: "butyl", label: "Butyl", value: "Butyl"},
    {key: "cpvc", label: "CPVC", value: "CPVC"},
    {key: "cadmium", label: "Cadmium", value: "Cadmium"},
    {key: "calcite", label: "Calcite", value: "Calcite"},
    {key: "carbide", label: "Carbide", value: "Carbide"},
    {key: "carbon_steel", label: "Carbon Steel", value: "Carbon Steel"},
    {
      key: "ceramic_iron_oxide",
      label: "Ceramic Iron Oxide",
      value: "Ceramic Iron Oxide",
    },
    {key: "cermet", label: "Cermet", value: "Cermet"},
    {key: "cerro_alloys", label: "Cerro Alloys", value: "Cerro Alloys"},
    {key: "chloroprene", label: "Chloroprene", value: "Chloroprene"},
    {
      key: "chlorosulfonated_polyethylene",
      label: "Chlorosulfonated Polyethylene (CSM)",
      value: "Chlorosulfonated Polyethylene (CSM)",
    },
    {key: "chromium", label: "Chromium", value: "Chromium"},
    {key: "chromium_oxide", label: "Chromium Oxide", value: "Chromium Oxide"},
    {key: "cobalt", label: "Cobalt", value: "Cobalt"},
    {key: "cobalt_steel", label: "Cobalt Steel", value: "Cobalt Steel"},
    {
      key: "cold_rolled_steel",
      label: "Cold Rolled Steel",
      value: "Cold Rolled Steel",
    },
    {key: "copper", label: "Copper", value: "Copper"},
    {key: "cordierite", label: "Cordierite", value: "Cordierite"},
    {key: "corn_cob", label: "Corn Cob", value: "Corn Cob"},
    {key: "corundum", label: "Corundum", value: "Corundum"},
    {key: "crocus", label: "Crocus", value: "Crocus"},
    {key: "crystal_1", label: "Crystal", value: "Crystal"},
    {
      key: "cubic_boron_nitride",
      label: "Cubic Boron Nitride",
      value: "Cubic Boron Nitride",
    },
    {
      key: "cubic_boron_nitride_cbn",
      label: "Cubic Boron Nitride (CBN)",
      value: "Cubic Boron Nitride (CBN)",
    },
    {key: "diamond", label: "Diamond", value: "Diamond"},
    {key: "elastomers", label: "Elastomers", value: "Elastomers"},
    {key: "emery", label: "Emery", value: "Emery"},
    {key: "fabric", label: "Fabric", value: "Fabric"},
    {key: "felt", label: "Felt", value: "Felt"},
    {key: "fiberglass", label: "Fiberglass", value: "Fiberglass"},
    {key: "fibralon", label: "Fibralon", value: "Fibralon"},
    {key: "foam", label: "Foam", value: "Foam"},
    {key: "forged", label: "Forged", value: "Forged"},
    {
      key: "galvanized_steel",
      label: "Galvanized Steel",
      value: "Galvanized Steel",
    },
    {key: "garnet", label: "Garnet", value: "Garnet"},
    {
      key: "glass_aluminum_oxide",
      label: "Glass-Aluminum Oxide",
      value: "Glass-Aluminum Oxide",
    },
    {key: "gold", label: "Gold", value: "Gold"},
    {key: "gold_ferroud", label: "Gold Ferroud", value: "Gold Ferroud"},
    {key: "graphite", label: "Graphite", value: "Graphite"},
    {key: "gum_rubber", label: "Gum Rubber", value: "Gum Rubber"},
    {key: "hastelloy", label: "Hastelloy", value: "Hastelloy"},
    {key: "heat_treated", label: "Heat-Treated", value: "Heat-Treated"},
    {
      key: "high_density_polyethylene",
      label: "High Density Polyethylene (HDPE)",
      value: "High Density Polyethylene (HDPE)",
    },
    {
      key: "high_speed_steel",
      label: "High Speed Steel (HSS)",
      value: "High Speed Steel (HSS)",
    },
    {
      key: "hot_rolled_steel",
      label: "Hot Rolled Steel",
      value: "Hot Rolled Steel",
    },
    {key: "hydex", label: "Hydex", value: "Hydex"},
    {
      key: "hydrogenated_nitrile_butadiene_rubber",
      label: "Hydrogenated Nitrile Butadiene Rubber (HNBR)",
      value: "Hydrogenated Nitrile Butadiene Rubber (HNBR)",
    },
    {key: "inconel", label: "Inconel", value: "Inconel"},
    {key: "iron", label: "Iron", value: "Iron"},
    {
      key: "iron_cast_iron",
      label: "Iron & Cast Iron",
      value: "Iron & Cast Iron",
    },
    {key: "iron_oxide", label: "Iron Oxide", value: "Iron Oxide"},
    {key: "kalrez", label: "Kalrez", value: "Kalrez"},
    {key: "kevlar", label: "Kevlar", value: "Kevlar"},
    {key: "kraft_paper", label: "Kraft Paper", value: "Kraft Paper"},
    {key: "latex", label: "Latex", value: "Latex"},
    {key: "lead", label: "Lead", value: "Lead"},
    {key: "leather", label: "Leather", value: "Leather"},
    {key: "lexon", label: "Lexon", value: "Lexon"},
    {
      key: "liquid_silicone_rubber",
      label: "Liquid Silicone Rubber",
      value: "Liquid Silicone Rubber",
    },
    {
      key: "low_density_polyethylene",
      label: "Low Density Polyethylene (LDPE)",
      value: "Low Density Polyethylene (LDPE)",
    },
    {key: "magnesium", label: "Magnesium", value: "Magnesium"},
    {
      key: "manganese_steel",
      label: "Manganese Steel",
      value: "Manganese Steel",
    },
    {key: "maraging_steel", label: "Maraging Steel", value: "Maraging Steel"},
    {key: "marble", label: "Marble", value: "Marble"},
    {key: "metal_1", label: "Metal", value: "Metal"},
    {key: "molded_epoxy", label: "Molded Epoxy", value: "Molded Epoxy"},
    {
      key: "molybdenum_steel",
      label: "Molybdenum Steel",
      value: "Molybdenum Steel",
    },
    {key: "mullite", label: "Mullite", value: "Mullite"},
    {key: "natural_rubber", label: "Natural Rubber", value: "Natural Rubber"},
    {key: "neoprene", label: "Neoprene", value: "Neoprene"},
    {key: "nickel", label: "Nickel", value: "Nickel"},
    {key: "nitinol", label: "Nitinol", value: "Nitinol"},
    {key: "nitrile_rubber", label: "Nitrile Rubber", value: "Nitrile Rubber"},
    {key: "novaculite", label: "Novaculite", value: "Novaculite"},
    {key: "nylon", label: "Nylon", value: "Nylon"},
    {key: "oak", label: "Oak", value: "Oak"},
    {key: "petg", label: "PETG", value: "PETG"},
    {key: "pex", label: "PEX", value: "PEX"},
    {key: "pvc", label: "PVC", value: "PVC"},
    {key: "paper", label: "Paper", value: "Paper"},
    {key: "pebax", label: "Pebax", value: "Pebax"},
    {
      key: "perfluoralkoxy",
      label: "Perfluoralkoxy (PFA)",
      value: "Perfluoralkoxy (PFA)",
    },
    {
      key: "perfluoroelastomer",
      label: "Perfluoroelastomer",
      value: "Perfluoroelastomer",
    },
    {key: "pewter", label: "Pewter", value: "Pewter"},
    {key: "phenolic", label: "Phenolic", value: "Phenolic"},
    {key: "pine", label: "Pine", value: "Pine"},
    {key: "plastic_1", label: "Plastic", value: "Plastic"},
    {key: "platinum", label: "Platinum", value: "Platinum"},
    {key: "polyacrylate", label: "Polyacrylate", value: "Polyacrylate"},
    {
      key: "polyamide_imide",
      label: "Polyamide Imide",
      value: "Polyamide Imide",
    },
    {
      key: "polybutylene_terephthalate",
      label: "Polybutylene Terephtalate (PBT)",
      value: "Polybutylene Terephtalate (PBT)",
    },
    {key: "polycarbonate", label: "Polycarbonate", value: "Polycarbonate"},
    {
      key: "polychloroprene",
      label: "Polychloroprene",
      value: "Polychloroprene",
    },
    {
      key: "polycrystalline_diamond",
      label: "Polycrystalline Diamond (PCD)",
      value: "Polycrystalline Diamond (PCD)",
    },
    {key: "polyester", label: "Polyester", value: "Polyester"},
    {
      key: "peek",
      label: "Polyetheretherketone (PEEK)",
      value: "Polyetheretherketone (PEEK)",
    },
    {key: "polyetherimide", label: "Polyetherimide", value: "Polyetherimide"},
    {
      key: "polyethersulfone",
      label: "Polyethersulfone",
      value: "Polyethersulfone",
    },
    {key: "polyethylene", label: "Polyethylene", value: "Polyethylene"},
    {key: "polyimide", label: "Polyimide", value: "Polyimide"},
    {key: "polymer", label: "Polymer", value: "Polymer"},
    {
      key: "polymethylpentane",
      label: "Polymethylpentane",
      value: "Polymethylpentane",
    },
    {key: "polyolefin", label: "Polyolefin", value: "Polyolefin"},
    {
      key: "delrin",
      label: "Polyoxymethylene (Delrin)",
      value: "Polyoxymethylene (Delrin)",
    },
    {
      key: "noryl_en265",
      label: "Polyphenylene Ether (NORYL EN-265)",
      value: "Polyphenylene Ether (NORYL EN-265)",
    },
    {
      key: "polyphenylene_oxide",
      label: "Polyphenylene Oxide",
      value: "Polyphenylene Oxide",
    },
    {
      key: "pps",
      label: "Polyphenylene Sulfide (PPS)",
      value: "Polyphenylene Sulfide (PPS)",
    },
    {key: "polypropylene", label: "Polypropylene", value: "Polypropylene"},
    {key: "polystyrene", label: "Polystyrene", value: "Polystyrene"},
    {key: "polysulfone", label: "Polysulfone", value: "Polysulfone"},
    {
      key: "fluorosint_500_ptfe",
      label: "Polytetrafluoroethylene (FLUOROSINT 500 PTFE)",
      value: "Polytetrafluoroethylene (FLUOROSINT 500 PTFE)",
    },
    {
      key: "ptfe",
      label: "Polytetrafluoroethylene (PTFE)",
      value: "Polytetrafluoroethylene (PTFE)",
    },
    {key: "polyurethane", label: "Polyurethane", value: "Polyurethane"},
    {
      key: "pva",
      label: "Polyvinyl Acetate (PVA)",
      value: "Polyvinyl Acetate (PVA)",
    },
    {
      key: "pvc_type_i",
      label: "Polyvinyl Chloride Type I",
      value: "Polyvinyl Chloride Type I",
    },
    {
      key: "pvc_type_ii",
      label: "Polyvinyl Chloride Type II",
      value: "Polyvinyl Chloride Type II",
    },
    {
      key: "pvdf",
      label: "Polyvinylidene Fluoride (PVDF)",
      value: "Polyvinylidene Fluoride (PVDF)",
    },
    {key: "porcelain", label: "Porcelain", value: "Porcelain"},
    {
      key: "powdered_metal_cobalt_steel",
      label: "Powdered Metal Cobalt Steel",
      value: "Powdered Metal Cobalt Steel",
    },
    {
      key: "powdered_metal_steel",
      label: "Powdered Metal Steel",
      value: "Powdered Metal Steel",
    },
    {key: "pumice", label: "Pumice", value: "Pumice"},
    {key: "quartz", label: "Quartz", value: "Quartz"},
    {key: "resin", label: "Resin", value: "Resin"},
    {key: "rhodium", label: "Rhodium", value: "Rhodium"},
    {key: "rosewood", label: "Rosewood", value: "Rosewood"},
    {key: "rubber", label: "Rubber", value: "Rubber"},
    {key: "ruby", label: "Ruby", value: "Ruby"},
    {key: "rulon_ar", label: "Rulon AR", value: "Rulon AR"},
    {key: "rulon_j", label: "Rulon J", value: "Rulon J"},
    {key: "rulon_lr", label: "Rulon LR", value: "Rulon LR"},
    {key: "sand", label: "Sand", value: "Sand"},
    {
      key: "silicon_carbide",
      label: "Silicon Carbide",
      value: "Silicon Carbide",
    },
    {
      key: "silicon_carbide_aluminum_oxide",
      label: "Silicon Carbide-Aluminum Oxide",
      value: "Silicon Carbide-Aluminum Oxide",
    },
    {
      key: "silicon_dioxide",
      label: "Silicon Dioxide (Silica)",
      value: "Silicon Dioxide (Silica)",
    },
    {
      key: "silicon_manganese_steel",
      label: "Silicon Manganese Steel",
      value: "Silicon Manganese Steel",
    },
    {
      key: "silicone_rubber",
      label: "Silicone Rubber",
      value: "Silicone Rubber",
    },
    {key: "sillimanite", label: "Sillimanite", value: "Sillimanite"},
    {key: "silver", label: "Silver", value: "Silver"},
    {key: "simriz", label: "Simriz", value: "Simriz"},
    {key: "spring_steel", label: "Spring Steel", value: "Spring Steel"},
    {
      key: "stainless_steel",
      label: "Stainless Steel",
      value: "Stainless Steel",
    },
    {key: "steatite", label: "Steatite", value: "Steatite"},
    {key: "steel", label: "Steel", value: "Steel"},
    {key: "steel_shot", label: "Steel Shot", value: "Steel Shot"},
    {key: "stone", label: "Stone", value: "Stone"},
    {
      key: "styrene_butadiene_rubber",
      label: "Styrene-butadiene Rubber",
      value: "Styrene-butadiene Rubber",
    },
    {
      key: "pmma",
      label: "Synthetic Polymer (PMMA)",
      value: "Synthetic Polymer (PMMA)",
    },
    {key: "synthetic_ruby", label: "Synthetic Ruby", value: "Synthetic Ruby"},
    {
      key: "synthetic_sapphire",
      label: "Synthetic Sapphire",
      value: "Synthetic Sapphire",
    },
    {key: "talc", label: "Talc", value: "Talc"},
    {key: "teak", label: "Teak", value: "Teak"},
    {
      key: "tetrafluoroethylene_hexafluoropropylene_vinylidene_fluoride",
      label: "Tetrafluoroethylene Hexafluoropropylene Vinylidene Fluoride",
      value: "Tetrafluoroethylene Hexafluoropropylene Vinylidene Fluoride",
    },
    {key: "thin_film", label: "Thin Film", value: "Thin Film"},
    {key: "tin", label: "Tin", value: "Tin"},
    {key: "titanium", label: "Titanium", value: "Titanium"},
    {key: "tool_steel", label: "Tool Steel", value: "Tool Steel"},
    {key: "torlon_4203", label: "Torlon 4203", value: "Torlon 4203"},
    {key: "torlon_4301", label: "Torlon 4301", value: "Torlon 4301"},
    {key: "tungsten", label: "Tungsten", value: "Tungsten"},
    {key: "turcite", label: "Turcite", value: "Turcite"},
    {
      key: "uhmw",
      label: "UHMW (Ultra High Molecular Weight)",
      value: "UHMW (Ultra High Molecular Weight)",
    },
    {key: "urethane", label: "Urethane", value: "Urethane"},
    {key: "viton", label: "Viton", value: "Viton"},
    {key: "viton_extreme", label: "Viton Extreme", value: "Viton Extreme"},
    {key: "walnut", label: "Walnut", value: "Walnut"},
    {key: "walnut_shell", label: "Walnut Shell", value: "Walnut Shell"},
    {
      key: "white_high_impact_styrene",
      label: "White High-impact Styrene",
      value: "White High-impact Styrene",
    },
    {key: "willow", label: "Willow", value: "Willow"},
    {key: "wire_wound", label: "Wire Wound", value: "Wire Wound"},
    {key: "wood", label: "Wood", value: "Wood"},
    {key: "wood_laminate", label: "Wood Laminate", value: "Wood Laminate"},
    {key: "wool", label: "Wool", value: "Wool"},
    {key: "zinc", label: "Zinc", value: "Zinc"},
    {key: "zirconia", label: "Zirconia", value: "Zirconia"},
    {
      key: "zirconia_alumina",
      label: "Zirconia Alumina",
      value: "Zirconia Alumina",
    },
    {
      key: "zirconia_alumina_aluminum_oxide",
      label: "Zirconia Alumina-Aluminum Oxide",
      value: "Zirconia Alumina-Aluminum Oxide",
    },
    {
      key: "zirconia_alumina_silicon_carbide",
      label: "Zirconia Alumina-Silicon Carbide",
      value: "Zirconia Alumina-Silicon Carbide",
    },
    {
      key: "zirkon_aluminum",
      label: "Zirkon Aluminum",
      value: "Zirkon Aluminum",
    },
  ],
  finish_type: [
    {key: "Finished", label: "Finished", value: "Finished"},
    {key: "Glossy", label: "Glossy", value: "Glossy"},
    {key: "Hand-Scraped", label: "Hand-Scraped", value: "Hand-Scraped"},
    {
      key: "High Speed Steel",
      label: "High Speed Steel",
      value: "High Speed Steel",
    },
    {key: "Matte", label: "Matte", value: "Matte"},
    {key: "Polished", label: "Polished", value: "Polished"},
    {
      key: "Stainless-Steel",
      label: "Stainless-Steel",
      value: "Stainless-Steel",
    },
    {key: "Tialn", label: "Tialn", value: "Tialn"},
    {key: "TiCN", label: "TiCN", value: "TiCN"},
    {key: "Uncoated", label: "Uncoated", value: "Uncoated"},
    {key: "Zinc Plated", label: "Zinc Plated", value: "Zinc Plated"},
    {key: "Cast", label: "Cast", value: "Cast"},
    {key: "Coated", label: "Coated", value: "Coated"},
    {key: "Antique", label: "Antique", value: "Antique"},
    {key: "Biscuit", label: "Biscuit", value: "Biscuit"},
    {key: "Brass", label: "Brass", value: "Brass"},
    {key: "Unfinished", label: "Unfinished", value: "Unfinished"},
    {key: "Iron", label: "Iron", value: "Iron"},
    {key: "Machined", label: "Machined", value: "Machined"},
    {key: "Cobalt", label: "Cobalt", value: "Cobalt"},
    {key: "Silk", label: "Silk", value: "Silk"},
    {key: "Satin", label: "Satin", value: "Satin"},
    {key: "Pewter", label: "Pewter", value: "Pewter"},
    {key: "TiN", label: "TiN", value: "TiN"},
    {key: "Chrome", label: "Chrome", value: "Chrome"},
    {key: "Limewash", label: "Limewash", value: "Limewash"},
    {
      key: "Tungsten Carbide",
      label: "Tungsten Carbide",
      value: "Tungsten Carbide",
    },
    {key: "Copper", label: "Copper", value: "Copper"},
    {key: "Stainless", label: "Stainless", value: "Stainless"},
    {key: "Venetian", label: "Venetian", value: "Venetian"},
    {key: "Bronze", label: "Bronze", value: "Bronze"},
    {key: "Brushed Bronze", label: "Brushed Bronze", value: "Brushed Bronze"},
    {key: "Gold", label: "Gold", value: "Gold"},
    {key: "TiB2", label: "TiB2", value: "TiB2"},
    {key: "Multiple", label: "Multiple", value: "Multiple"},
    {key: "Stamped", label: "Stamped", value: "Stamped"},
    {key: "Brushed Nickel", label: "Brushed Nickel", value: "Brushed Nickel"},
    {key: "AlTiN", label: "AlTiN", value: "AlTiN"},
    {key: "Brushed Chrome", label: "Brushed Chrome", value: "Brushed Chrome"},
    {key: "Masonry", label: "Masonry", value: "Masonry"},
    {key: "Steel", label: "Steel", value: "Steel"},
    {key: "Eggshell", label: "Eggshell", value: "Eggshell"},
    {key: "Silver", label: "Silver", value: "Silver"},
    {key: "TiSiN", label: "TiSiN", value: "TiSiN"},
    {key: "Metallic", label: "Metallic", value: "Metallic"},
    {key: "Mixed", label: "Mixed", value: "Mixed"},
    {key: "Ceramic", label: "Ceramic", value: "Ceramic"},
    {key: "Nickel", label: "Nickel", value: "Nickel"},
    {key: "Brushed Brass", label: "Brushed Brass", value: "Brushed Brass"},
    {key: "Brushed Steel", label: "Brushed Steel", value: "Brushed Steel"},
    {key: "Painted", label: "Painted", value: "Painted"},
    {key: "Polished Brass", label: "Polished Brass", value: "Polished Brass"},
    {
      key: "Polished Chrome",
      label: "Polished Chrome",
      value: "Polished Chrome",
    },
    {key: "Polished Steel", label: "Polished Steel", value: "Polished Steel"},
    {key: "Intelligent", label: "Intelligent", value: "Intelligent"},
    {key: "Hand Scraped", label: "Hand Scraped", value: "Hand Scraped"},
  ],
  plug_type: [
    {
      key: "A",
      label: "A - Two flat parallel plugs. American type.",
      value: "A",
    },
    {
      key: "B",
      label:
        "B - Two flat parallel plugs with ground plug. American type with ground plug.",
      value: "B",
    },
    {key: "C", label: "C - Two round plugs. French type.", value: "C"},
    {
      key: "D",
      label:
        "D - Two round plugs with ground plug. French type with ground plug.",
      value: "D",
    },
    {
      key: "E",
      label: "E - Two round plugs with male ground plug.",
      value: "E",
    },
    {key: "F", label: "F - Schuko type with two ground plugs.", value: "F"},
    {key: "G", label: "G - Square plugs. British type.", value: "G"},
    {key: "H", label: "H - V plugs with ground plug.", value: "H"},
    {key: "I", label: "I - Inversed V plugs with ground plug.", value: "I"},
    {key: "J", label: "J - Round plug with ground plug.", value: "J"},
    {
      key: "K",
      label: "K - Plug-in plugs: rectangular with ground plug.",
      value: "K",
    },
    {
      key: "L",
      label: "L - Round plugs with aligned ground plug.",
      value: "L",
    },
    {
      key: "M",
      label: "M - Two large round plugs with ground plug.",
      value: "M",
    },
  ],
  special_features: [
    {key: "Auto on/off", label: "Auto on/off", value: "Auto on/off"},
    {key: "Built in timer", label: "Built in timer", value: "Built in timer"},
    {key: "Dimmable", label: "Dimmable", value: "Dimmable"},
    {key: "Lap timer", label: "Lap timer", value: "Lap timer"},
    {
      key: "Back up battery",
      label: "Back up battery",
      value: "Back up battery",
    },
    {key: "Bike computer", label: "Bike computer", value: "Bike computer"},
    {key: "Calculator", label: "Calculator", value: "Calculator"},
    {key: "Chronograph", label: "Chronograph", value: "Chronograph"},
    {key: "Compass GPS", label: "Compass GPS", value: "Compass GPS"},
    {
      key: "Depth measurement",
      label: "Depth measurement",
      value: "Depth measurement",
    },
    {
      key: "Dual time display",
      label: "Dual time display",
      value: "Dual time display",
    },
    {
      key: "Heart rate monitor",
      label: "Heart rate monitor",
      value: "Heart rate monitor",
    },
    {key: "Light", label: "Light", value: "Light"},
    {key: "Luminous", label: "Luminous", value: "Luminous"},
    {
      key: "Outdoor computer",
      label: "Outdoor computer",
      value: "Outdoor computer",
    },
    {
      key: "Radio Controlled",
      label: "Radio Controlled",
      value: "Radio Controlled",
    },
    {
      key: "Screw down crown",
      label: "Screw down crown",
      value: "Screw down crown",
    },
    {key: "Second hand", label: "Second hand", value: "Second hand"},
    {
      key: "Shock resistant",
      label: "Shock resistant",
      value: "Shock resistant",
    },
    {key: "Slide rule", label: "Slide rule", value: "Slide rule"},
    {key: "Stop watch", label: "Stop watch", value: "Stop watch"},
    {key: "Tachometer", label: "Tachometer", value: "Tachometer"},
    {key: "Timer", label: "Timer", value: "Timer"},
    {key: "World time", label: "World time", value: "World time"},
    {
      key: "20 degree wire colated",
      label: "20 degree wire colated",
      value: "20 degree wire colated",
    },
    {
      key: "28 degree plastic coated",
      label: "28 degree plastic coated",
      value: "28 degree plastic coated",
    },
    {
      key: "32 degree paper colated",
      label: "32 degree paper colated",
      value: "32 degree paper colated",
    },
    {
      key: "adjustable shoe",
      label: "adjustable shoe",
      value: "adjustable shoe",
    },
    {
      key: "Air inlet thread size (NPT)",
      label: "Air inlet thread size (NPT)",
      value: "Air inlet thread size (NPT)",
    },
    {key: "Bell", label: "Bell", value: "Bell"},
    {key: "Carbide height", label: "Carbide height", value: "Carbide height"},
    {key: "Contact points", label: "Contact points", value: "Contact points"},
    {
      key: "Diode 635 nanometers",
      label: "Diode 635 nanometers",
      value: "Diode 635 nanometers",
    },
    {
      key: "Dust collection",
      label: "Dust collection",
      value: "Dust collection",
    },
    {
      key: "Dust cycle percentage",
      label: "Dust cycle percentage",
      value: "Dust cycle percentage",
    },
    {
      key: "Dust cycle time",
      label: "Dust cycle time",
      value: "Dust cycle time",
    },
    {key: "Electric brake", label: "Electric brake", value: "Electric brake"},
    {key: "Electrical", label: "Electrical", value: "Electrical"},
    {
      key: "Electrode thickness 1/4 inch",
      label: "Electrode thickness 1/4 inch",
      value: "Electrode thickness 1/4 inch",
    },
    {
      key: "Electrode type copper",
      label: "Electrode type copper",
      value: "Electrode type copper",
    },
    {
      key: "Electrode type mild steel",
      label: "Electrode type mild steel",
      value: "Electrode type mild steel",
    },
    {key: "Extra height", label: "Extra height", value: "Extra height"},
    {key: "Flag", label: "Flag", value: "Flag"},
    {key: "Hose size", label: "Hose size", value: "Hose size"},
    {
      key: "Impact resistance",
      label: "Impact resistance",
      value: "Impact resistance",
    },
    {
      key: "Keyless features",
      label: "Keyless features",
      value: "Keyless features",
    },
    {key: "Latch throw", label: "Latch throw", value: "Latch throw"},
    {
      key: "Lighting interface",
      label: "Lighting interface",
      value: "Lighting interface",
    },
    {key: "Lockable", label: "Lockable", value: "Lockable"},
    {key: "Magnetic", label: "Magnetic", value: "Magnetic"},
    {key: "Mute", label: "Mute", value: "Mute"},
    {key: "Name plate", label: "Name plate", value: "Name plate"},
    {key: "Newspaper roll", label: "Newspaper roll", value: "Newspaper roll"},
    {key: "Night vision", label: "Night vision", value: "Night vision"},
    {key: "Orbital action", label: "Orbital action", value: "Orbital action"},
    {key: "Outlets", label: "Outlets", value: "Outlets"},
    {key: "Paintable", label: "Paintable", value: "Paintable"},
    {key: "Panic button", label: "Panic button", value: "Panic button"},
    {key: "Portable", label: "Portable", value: "Portable"},
    {key: "Positive stop", label: "Positive stop", value: "Positive stop"},
    {
      key: "Rechargeable battery",
      label: "Rechargeable battery",
      value: "Rechargeable battery",
    },
    {key: "Removable", label: "Removable", value: "Removable"},
    {
      key: "Self Ttghtening",
      label: "Self Ttghtening",
      value: "Self Ttghtening",
    },
    {key: "Variable speed", label: "Variable speed", value: "Variable speed"},
    {key: "Video", label: "Video", value: "Video"},
    {key: "Washable", label: "Washable", value: "Washable"},
    {key: "Waterproof", label: "Waterproof", value: "Waterproof"},
    {key: "Wheels", label: "Wheels", value: "Wheels"},
    {key: "Wireless", label: "Wireless", value: "Wireless"},
    {
      key: "2-way switching",
      label: "2-way switching",
      value: "2-way switching",
    },
    {
      key: "Shatter resistant",
      label: "Shatter resistant",
      value: "Shatter resistant",
    },
    {
      key: "Shock and vibration resistant",
      label: "Shock and vibration resistant",
      value: "Shock and vibration resistant",
    },
    {
      key: "Non bug attracting",
      label: "Non bug attracting",
      value: "Non bug attracting",
    },
    {
      key: "3 way switching",
      label: "3 way switching",
      value: "3 way switching",
    },
  ],
  item_weight_unit_of_measure: [
    {key: "pound_(lb)", label: "Pound (LB)", value: "Pound (LB)"},
    {key: "kilogram_(kg)", label: "Kilogram (KG)", value: "Kilogram (KG)"},
    {key: "gram_(gr)", label: "Gram (GR)", value: "Gram (GR)"},
    {
      key: "hundredths_pounds",
      label: "Hundredths pounds",
      value: "Hundredths pounds",
    },
    {key: "milligram_(mg)", label: "Milligram (MG)", value: "Milligram (MG)"},
    {key: "tons", label: "Tons", value: "Tons"},
    {key: "ounce_(oz)", label: "Ounce (OZ)", value: "Ounce (OZ)"},
  ],
  item_volume_unit_of_measure: [
    {key: "cup", label: "Cup", value: "Cup"},
    {key: "microliters", label: "Microliters", value: "Microliters"},
    {key: "cubic-ft", label: "Cubic Feet", value: "Cubic Feet"},
    {key: "centiliters", label: "Centiliters", value: "Centiliters"},
    {key: "gallon", label: "Gallon", value: "Gallon"},
    {key: "quarts", label: "Quarts", value: "Quarts"},
    {key: "nanoliters", label: "Nanoliters", value: "Nanoliters"},
    {key: "liter", label: "Liter", value: "Liter"},
    {key: "picoliters", label: "Picoliters", value: "Picoliters"},
    {
      key: "unknown_modifier",
      label: "Unknown Modifier",
      value: "Unknown Modifier",
    },
    {key: "fluid_ounces", label: "Fluid Ounces", value: "Fluid Ounces"},
    {key: "cubic-m", label: "Cubic Meters", value: "Cubic Meters"},
    {key: "pints", label: "Pints", value: "Pints"},
    {key: "cubic-yd", label: "Cubic Yards", value: "Cubic Yards"},
    {key: "cubic-in", label: "Cubic Inches", value: "Cubic Inches"},
    {
      key: "imperial_gallons",
      label: "Imperial Gallons",
      value: "Imperial Gallons",
    },
    {key: "milliliter", label: "Milliliters", value: "Milliliters"},
    {key: "cubic-cm", label: "Cubic Centimeters", value: "Cubic Centimeters"},
    {key: "deciliters", label: "Deciliters", value: "Deciliters"},
  ],
  battery_type: [
    {
      key: "Battery type AA",
      value: "Battery type AA",
      label: "Battery type AA",
    },
    {
      key: "Battery type AAA",
      value: "Battery type AAA",
      label: "Battery type AAA",
    },
    {
      key: "Battery type lithium ion",
      value: "Battery type lithium ion",
      label: "Battery type lithium ion",
    },
    {key: "Battery type A", value: "Battery type A", label: "Battery type A"},
    {
      key: "Battery type CR2",
      value: "Battery type CR2",
      label: "Battery type CR2",
    },
    {key: "Battery type C", value: "Battery type C", label: "Battery type C"},
    {key: "Battery type D", value: "Battery type D", label: "Battery type D"},
    {
      key: "Battery type CR5",
      value: "Battery type CR5",
      label: "Battery type CR5",
    },
    {key: "AAAA battery", value: "AAAA battery", label: "AAAA battery"},
    {
      key: "battery type P76",
      value: "Battery type P76",
      label: "Battery type P76",
    },
    {
      key: "Battery type product specific",
      value: "Battery type product specific",
      label: "Battery type product specific",
    },
    {key: "Lithiumpolymer", value: "Lithiumpolymer", label: "Lithiumpolymer"},
    {key: "Unknown", value: "Unknown", label: "Unknown"},
    {
      key: "Battery type lithium metal",
      value: "Battery type lithium metal",
      label: "Battery type lithium metal",
    },
    {
      key: "Nonstandard battery",
      value: "Nonstandard battery",
      label: "Nonstandard battery",
    },
    {
      key: "Battery type CR123A",
      value: "Battery type CR123A",
      label: "Battery type CR123A",
    },
    {key: "lr44", value: "lr44", label: "lr44"},
    {key: "12V battery", value: "12V battery", label: "12V battery"},
    {
      key: "Battery type 9V",
      value: "Battery type 9V",
      label: "Battery type 9V",
    },
  ],
  warranty_term: [
    {
      key: "3 months warranty",
      label: "3 months warranty",
      value: "3 months warranty",
    },
    {
      key: "4 months warranty",
      label: "4 months warranty",
      value: "4 months warranty",
    },
    {
      key: "5 months warranty",
      label: "5 months warranty",
      value: "5 months warranty",
    },
    {
      key: "6 months warranty",
      label: "6 months warranty",
      value: "6 months warranty",
    },
    {
      key: "7 months warranty",
      label: "7 months warranty",
      value: "7 months warranty",
    },
    {
      key: "8 months warranty",
      label: "8 months warranty",
      value: "8 months warranty",
    },
    {
      key: "9 months warranty",
      label: "9 months warranty",
      value: "9 months warranty",
    },
    {
      key: "10 months warranty",
      label: "10 months warranty",
      value: "10 months warranty",
    },
    {
      key: "11 months warranty",
      label: "11 months warranty",
      value: "11 months warranty",
    },
    {
      key: "1 year warranty",
      label: "1 year warranty",
      value: "1 year warranty",
    },
    {
      key: "2 years warranty",
      label: "2 years warranty",
      value: "2 years warranty",
    },
    {
      key: "3 years warranty",
      label: "3 years warranty",
      value: "3 years warranty",
    },
    {
      key: "4 years warranty",
      label: "4 years warranty",
      value: "4 years warranty",
    },
    {
      key: "5 years warranty",
      label: "5 years warranty",
      value: "5 years warranty",
    },
    {
      key: "6 years warranty",
      label: "6 years warranty",
      value: "6 years warranty",
    },
    {
      key: "7 years warranty",
      label: "7 years warranty",
      value: "7 years warranty",
    },
    {
      key: "8 years warranty",
      label: "8 years warranty",
      value: "8 years warranty",
    },
    {
      key: "9 years warranty",
      label: "9 years warranty",
      value: "9 years warranty",
    },
    {
      key: "10 years warranty",
      label: "10 years warranty",
      value: "10 years warranty",
    },
    {
      key: "11 years warranty",
      label: "11 years warranty",
      value: "11 years warranty",
    },
    {
      key: "12 years warranty",
      label: "12 years warranty",
      value: "12 years warranty",
    },
    {
      key: "13 years warranty",
      label: "13 years warranty",
      value: "13 years warranty",
    },
    {
      key: "14 years warranty",
      label: "14 years warranty",
      value: "14 years warranty",
    },
    {
      key: "15 years warranty",
      label: "15 years warranty",
      value: "15 years warranty",
    },
    {
      key: "Lifetime warranty",
      label: "Lifetime warranty",
      value: "Lifetime warranty",
    },
    {
      key: "13 months warranty",
      label: "13 months warranty",
      value: "13 months warranty",
    },
    {
      key: "14 months warranty",
      label: "14 months warranty",
      value: "14 months warranty",
    },
    {
      key: "15 months warranty",
      label: "15 months warranty",
      value: "15 months warranty",
    },
    {
      key: "16 months warranty",
      label: "16 months warranty",
      value: "16 months warranty",
    },
    {
      key: "17 months warranty",
      label: "17 months warranty",
      value: "17 months warranty",
    },
    {
      key: "18 months warranty",
      label: "18 months warranty",
      value: "18 months warranty",
    },
    {
      key: "19 months warranty",
      label: "19 months warranty",
      value: "19 months warranty",
    },
    {
      key: "20 months warranty",
      label: "20 months warranty",
      value: "20 months warranty",
    },
    {
      key: "21 months warranty",
      label: "21 months warranty",
      value: "21 months warranty",
    },
    {
      key: "22 months warranty",
      label: "22 months warranty",
      value: "22 months warranty",
    },
    {
      key: "23 months warranty",
      label: "23 months warranty",
      value: "23 months warranty",
    },
  ],
  return_refund_criteria: [
    {
      key: "7 days free return",
      label: "7 days free return",
      value: "7 days free return",
    },
    {
      key: "No return or refund",
      label: "No return or refund",
      value: "no return or refund",
    },
    {
      key: "14 days free return",
      label: "14 days free return",
      value: "14 days free return",
    },
    {
      key: "30 days free return",
      label: "30 days free return",
      value: "30 days free return",
    },
  ],
  "Comission Percent": [
    0.15, 0.1, 0.075, 0.05, 0.03, 0.3, 0.29, 0.28, 0.27, 0.26, 0.25, 0.24, 0.23,
    0.22, 0.21, 0.2, 0.19, 0.18, 0.17, 0.16, 0.14, 0.13, 0.12, 0.11, 0.09, 0.08,
    0.07, 0.06, 0.04,
  ],
  "MOB cities": [
    "Chennai",
    "Hyderabad",
    "Delhi NCR",
    "Bengaluru",
    "Mumbai",
    "Kochi",
    "Jaipur",
    "Chandigarh",
    "Coimbatore",
    "Goa",
    "Kolkata",
    "Mysore",
  ],
  "MOB personnel name ID": [
    "Kumar Vivek (0001)",
    "Johann Elias Terfurth (0002)",
  ],
  variation_theme: [
    {key: "width", label: "Width", value: "Width"},
    {key: "display_weight", label: "Display weight", value: "Display weight"},
    {
      key: "number_of_items",
      label: "Number of items",
      value: "Number of items",
    },
    {
      key: "color_name_+_number_of_items",
      label: "Color name + number of items",
      value: "Color name + number of items",
    },
    {
      key: "size_name_+_number_of_items",
      label: "Size name + number of items",
      value: "Size name + number of items",
    },
    {key: "material", label: "Material", value: "Material"},
    {
      key: "material_+_size",
      label: "Material + size",
      value: "Material + size",
    },
    {key: "pattern_name", label: "Pattern name", value: "Pattern name"},
    {key: "size_name", label: "Size name", value: "Size name"},
    {
      key: "size_name_+_color_name",
      label: "Size name + color name",
      value: "Size name + color name",
    },
    {key: "display_height", label: "Display height", value: "Display height"},
    {key: "color_name", label: "Color name", value: "Color name"},
    {key: "pattern", label: "Pattern", value: "Pattern"},
    {
      key: "display_height_+_display_length",
      label: "Display height + display length",
      value: "Display height + display length",
    },
    {key: "display_length", label: "Display length", value: "Display length"},
    {key: "display_width", label: "Display width", value: "Display width"},
    {key: "volume", label: "Volume", value: "Volume"},
    {key: "colour+volume", label: "Colour+Volume", value: "Colour+Volume"},
    {key: "colour+weight", label: "Colour+Weight", value: "Colour+Weight"},
    {
      key: "display_length_+_display_width",
      label: "Display length + display width",
      value: "Display length + display width",
    },
  ],
  country: [
    {key: "Afghanistan", label: "Afghanistan", value: "Afghanistan"},
    {key: "Aland Islands", label: "Aland Islands", value: "Aland Islands"},
    {key: "Albania", label: "Albania", value: "Albania"},
    {key: "Algeria", label: "Algeria", value: "Algeria"},
    {key: "American Samoa", label: "American Samoa", value: "American Samoa"},
    {key: "Andorra", label: "Andorra", value: "Andorra"},
    {key: "Angola", label: "Angola", value: "Angola"},
    {key: "Anguilla", label: "Anguilla", value: "Anguilla"},
    {key: "Antarctica", label: "Antarctica", value: "Antarctica"},
    {
      key: "Antigua and Barbuda",
      label: "Antigua and Barbuda",
      value: "Antigua and Barbuda",
    },
    {key: "Argentina", label: "Argentina", value: "Argentina"},
    {key: "Armenia", label: "Armenia", value: "Armenia"},
    {key: "Aruba", label: "Aruba", value: "Aruba"},
    {key: "Australia", label: "Australia", value: "Australia"},
    {key: "Austria", label: "Austria", value: "Austria"},
    {key: "Azerbaijan", label: "Azerbaijan", value: "Azerbaijan"},
    {key: "Bahamas", label: "Bahamas", value: "Bahamas"},
    {key: "Bahrain", label: "Bahrain", value: "Bahrain"},
    {key: "Bangladesh", label: "Bangladesh", value: "Bangladesh"},
    {key: "Barbados", label: "Barbados", value: "Barbados"},
    {key: "Belarus", label: "Belarus", value: "Belarus"},
    {key: "Belgium", label: "Belgium", value: "Belgium"},
    {key: "Belize", label: "Belize", value: "Belize"},
    {key: "Benin", label: "Benin", value: "Benin"},
    {key: "Bermuda", label: "Bermuda", value: "Bermuda"},
    {key: "Bhutan", label: "Bhutan", value: "Bhutan"},
    {key: "Bolivia", label: "Bolivia", value: "Bolivia"},
    {
      key: "Bosnia and Herzegovina",
      label: "Bosnia and Herzegovina",
      value: "Bosnia and Herzegovina",
    },
    {key: "Botswana", label: "Botswana", value: "Botswana"},
    {key: "Bouvet Island", label: "Bouvet Island", value: "Bouvet Island"},
    {key: "Brazil", label: "Brazil", value: "Brazil"},
    {
      key: "British Virgin Islands",
      label: "British Virgin Islands",
      value: "British Virgin Islands",
    },
    {
      key: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    {
      key: "Brunei Darussalam",
      label: "Brunei Darussalam",
      value: "Brunei Darussalam",
    },
    {key: "Bulgaria", label: "Bulgaria", value: "Bulgaria"},
    {key: "Burkina Faso", label: "Burkina Faso", value: "Burkina Faso"},
    {key: "Burundi", label: "Burundi", value: "Burundi"},
    {key: "Cambodia", label: "Cambodia", value: "Cambodia"},
    {key: "Cameroon", label: "Cameroon", value: "Cameroon"},
    {key: "Canada", label: "Canada", value: "Canada"},
    {key: "Cape Verde", label: "Cape Verde", value: "Cape Verde"},
    {key: "Cayman Islands", label: "Cayman Islands", value: "Cayman Islands"},
    {
      key: "Central African Republic",
      label: "Central African Republic",
      value: "Central African Republic",
    },
    {key: "Chad", label: "Chad", value: "Chad"},
    {key: "Chile", label: "Chile", value: "Chile"},
    {key: "China", label: "China", value: "China"},
    {key: "Hong Kong", label: "Hong Kong", value: "Hong Kong"},
    {key: "Macao", label: "Macao", value: "Macao"},
    {
      key: "Christmas Island",
      label: "Christmas Island",
      value: "Christmas Island",
    },
    {
      key: "Cocos (Keeling) Islands",
      label: "Cocos (Keeling) Islands",
      value: "Cocos (Keeling) Islands",
    },
    {key: "Colombia", label: "Colombia", value: "Colombia"},
    {key: "Comoros", label: "Comoros", value: "Comoros"},
    {
      key: "Congo (Brazzaville)",
      label: "Congo (Brazzaville)",
      value: "Congo (Brazzaville)",
    },
    {key: "Congo", label: "Congo", value: "Congo"},
    {key: "Cook Islands", label: "Cook Islands", value: "Cook Islands"},
    {key: "Costa Rica", label: "Costa Rica", value: "Costa Rica"},
    {key: "Côte d'Ivoire", label: "Côte d'Ivoire", value: "Côte d'Ivoire"},
    {key: "Croatia", label: "Croatia", value: "Croatia"},
    {key: "Cuba", label: "Cuba", value: "Cuba"},
    {key: "Cyprus", label: "Cyprus", value: "Cyprus"},
    {key: "Czech Republic", label: "Czech Republic", value: "Czech Republic"},
    {key: "Denmark", label: "Denmark", value: "Denmark"},
    {key: "Djibouti", label: "Djibouti", value: "Djibouti"},
    {key: "Dominica", label: "Dominica", value: "Dominica"},
    {
      key: "Dominican Republic",
      label: "Dominican Republic",
      value: "Dominican Republic",
    },
    {key: "Ecuador", label: "Ecuador", value: "Ecuador"},
    {key: "Egypt", label: "Egypt", value: "Egypt"},
    {key: "El Salvador", label: "El Salvador", value: "El Salvador"},
    {
      key: "Equatorial Guinea",
      label: "Equatorial Guinea",
      value: "Equatorial Guinea",
    },
    {key: "Eritrea", label: "Eritrea", value: "Eritrea"},
    {key: "Estonia", label: "Estonia", value: "Estonia"},
    {key: "Ethiopia", label: "Ethiopia", value: "Ethiopia"},
    {
      key: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
      value: "Falkland Islands (Malvinas)",
    },
    {key: "Faroe Islands", label: "Faroe Islands", value: "Faroe Islands"},
    {key: "Fiji", label: "Fiji", value: "Fiji"},
    {key: "Finland", label: "Finland", value: "Finland"},
    {key: "France", label: "France", value: "France"},
    {key: "French Guiana", label: "French Guiana", value: "French Guiana"},
    {
      key: "French Polynesia",
      label: "French Polynesia",
      value: "French Polynesia",
    },
    {
      key: "French Southern Territories",
      label: "French Southern Territories",
      value: "French Southern Territories",
    },
    {key: "Gabon", label: "Gabon", value: "Gabon"},
    {key: "Gambia", label: "Gambia", value: "Gambia"},
    {key: "Georgia", label: "Georgia", value: "Georgia"},
    {key: "Germany", label: "Germany", value: "Germany"},
    {key: "Ghana", label: "Ghana", value: "Ghana"},
    {key: "Gibraltar", label: "Gibraltar", value: "Gibraltar"},
    {key: "Greece", label: "Greece", value: "Greece"},
    {key: "Greenland", label: "Greenland", value: "Greenland"},
    {key: "Grenada", label: "Grenada", value: "Grenada"},
    {key: "Guadeloupe", label: "Guadeloupe", value: "Guadeloupe"},
    {key: "Guam", label: "Guam", value: "Guam"},
    {key: "Guatemala", label: "Guatemala", value: "Guatemala"},
    {key: "Guernsey", label: "Guernsey", value: "Guernsey"},
    {key: "Guinea", label: "Guinea", value: "Guinea"},
    {key: "Guinea-Bissau", label: "Guinea-Bissau", value: "Guinea-Bissau"},
    {key: "Guyana", label: "Guyana", value: "Guyana"},
    {key: "Haiti", label: "Haiti", value: "Haiti"},
    {
      key: "Heard Island and Mcdonald Islands",
      label: "Heard Island and Mcdonald Islands",
      value: "Heard Island and Mcdonald Islands",
    },
    {
      key: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
      value: "Holy See (Vatican City State)",
    },
    {key: "Honduras", label: "Honduras", value: "Honduras"},
    {key: "Hungary", label: "Hungary", value: "Hungary"},
    {key: "Iceland", label: "Iceland", value: "Iceland"},
    {key: "India", label: "India", value: "India"},
    {key: "Indonesia", label: "Indonesia", value: "Indonesia"},
    {key: "Iran", label: "Iran", value: "Iran"},
    {key: "Iraq", label: "Iraq", value: "Iraq"},
    {key: "Ireland", label: "Ireland", value: "Ireland"},
    {key: "Isle of Man", label: "Isle of Man", value: "Isle of Man"},
    {key: "Israel", label: "Israel", value: "Israel"},
    {key: "Italy", label: "Italy", value: "Italy"},
    {key: "Jamaica", label: "Jamaica", value: "Jamaica"},
    {key: "Japan", label: "Japan", value: "Japan"},
    {key: "Jersey", label: "Jersey", value: "Jersey"},
    {key: "Jordan", label: "Jordan", value: "Jordan"},
    {key: "Kazakhstan", label: "Kazakhstan", value: "Kazakhstan"},
    {key: "Kenya", label: "Kenya", value: "Kenya"},
    {key: "Kiribati", label: "Kiribati", value: "Kiribati"},
    {key: "North Korea", label: "North Korea", value: "North Korea"},
    {key: "South Korea", label: "South Korea", value: "South Korea"},
    {key: "Kuwait", label: "Kuwait", value: "Kuwait"},
    {key: "Kyrgyzstan", label: "Kyrgyzstan", value: "Kyrgyzstan"},
    {key: "Lao PDR", label: "Lao PDR", value: "Lao PDR"},
    {key: "Latvia", label: "Latvia", value: "Latvia"},
    {key: "Lebanon", label: "Lebanon", value: "Lebanon"},
    {key: "Lesotho", label: "Lesotho", value: "Lesotho"},
    {key: "Liberia", label: "Liberia", value: "Liberia"},
    {key: "Libya", label: "Libya", value: "Libya"},
    {key: "Liechtenstein", label: "Liechtenstein", value: "Liechtenstein"},
    {key: "Lithuania", label: "Lithuania", value: "Lithuania"},
    {key: "Luxembourg", label: "Luxembourg", value: "Luxembourg"},
    {key: "Macedonia", label: "Macedonia", value: "Macedonia"},
    {key: "Madagascar", label: "Madagascar", value: "Madagascar"},
    {key: "Malawi", label: "Malawi", value: "Malawi"},
    {key: "Malaysia", label: "Malaysia", value: "Malaysia"},
    {key: "Maldives", label: "Maldives", value: "Maldives"},
    {key: "Mali", label: "Mali", value: "Mali"},
    {key: "Malta", label: "Malta", value: "Malta"},
    {
      key: "Marshall Islands",
      label: "Marshall Islands",
      value: "Marshall Islands",
    },
    {key: "Martinique", label: "Martinique", value: "Martinique"},
    {key: "Mauritania", label: "Mauritania", value: "Mauritania"},
    {key: "Mauritius", label: "Mauritius", value: "Mauritius"},
    {key: "Mayotte", label: "Mayotte", value: "Mayotte"},
    {key: "Mexico", label: "Mexico", value: "Mexico"},
    {key: "Micronesia", label: "Micronesia", value: "Micronesia"},
    {key: "Moldova", label: "Moldova", value: "Moldova"},
    {key: "Monaco", label: "Monaco", value: "Monaco"},
    {key: "Mongolia", label: "Mongolia", value: "Mongolia"},
    {key: "Montenegro", label: "Montenegro", value: "Montenegro"},
    {key: "Montserrat", label: "Montserrat", value: "Montserrat"},
    {key: "Morocco", label: "Morocco", value: "Morocco"},
    {key: "Mozambique", label: "Mozambique", value: "Mozambique"},
    {key: "Myanmar", label: "Myanmar", value: "Myanmar"},
    {key: "Namibia", label: "Namibia", value: "Namibia"},
    {key: "Nauru", label: "Nauru", value: "Nauru"},
    {key: "Nepal", label: "Nepal", value: "Nepal"},
    {key: "Netherlands", label: "Netherlands", value: "Netherlands"},
    {
      key: "Netherlands Antilles",
      label: "Netherlands Antilles",
      value: "Netherlands Antilles",
    },
    {key: "New Caledonia", label: "New Caledonia", value: "New Caledonia"},
    {key: "New Zealand", label: "New Zealand", value: "New Zealand"},
    {key: "Nicaragua", label: "Nicaragua", value: "Nicaragua"},
    {key: "Niger", label: "Niger", value: "Niger"},
    {key: "Nigeria", label: "Nigeria", value: "Nigeria"},
    {key: "Niue", label: "Niue", value: "Niue"},
    {key: "Norfolk Island", label: "Norfolk Island", value: "Norfolk Island"},
    {
      key: "Northern Mariana Islands",
      label: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
    },
    {key: "Norway", label: "Norway", value: "Norway"},
    {key: "Oman", label: "Oman", value: "Oman"},
    {key: "Pakistan", label: "Pakistan", value: "Pakistan"},
    {key: "Palau", label: "Palau", value: "Palau"},
    {key: "Palestine", label: "Palestine", value: "Palestine"},
    {key: "Panama", label: "Panama", value: "Panama"},
    {
      key: "Papua New Guinea",
      label: "Papua New Guinea",
      value: "Papua New Guinea",
    },
    {key: "Paraguay", label: "Paraguay", value: "Paraguay"},
    {key: "Peru", label: "Peru", value: "Peru"},
    {key: "Philippines", label: "Philippines", value: "Philippines"},
    {key: "Pitcairn", label: "Pitcairn", value: "Pitcairn"},
    {key: "Poland", label: "Poland", value: "Poland"},
    {key: "Portugal", label: "Portugal", value: "Portugal"},
    {key: "Puerto Rico", label: "Puerto Rico", value: "Puerto Rico"},
    {key: "Qatar", label: "Qatar", value: "Qatar"},
    {key: "Réunion", label: "Réunion", value: "Réunion"},
    {key: "Romania", label: "Romania", value: "Romania"},
    {key: "Russia", label: "Russia", value: "Russia"},
    {key: "Rwanda", label: "Rwanda", value: "Rwanda"},
    {
      key: "Saint-Barthélemy",
      label: "Saint-Barthélemy",
      value: "Saint-Barthélemy",
    },
    {key: "Saint Helena", label: "Saint Helena", value: "Saint Helena"},
    {
      key: "Saint Kitts and Nevis",
      label: "Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis",
    },
    {key: "Saint Lucia", label: "Saint Lucia", value: "Saint Lucia"},
    {
      key: "Saint-Martin (French part)",
      label: "Saint-Martin (French part)",
      value: "Saint-Martin (French part)",
    },
    {
      key: "Saint Pierre and Miquelon",
      label: "Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon",
    },
    {
      key: "Saint Vincent and Grenadines",
      label: "Saint Vincent and Grenadines",
      value: "Saint Vincent and Grenadines",
    },
    {key: "Samoa", label: "Samoa", value: "Samoa"},
    {key: "San Marino", label: "San Marino", value: "San Marino"},
    {
      key: "Sao Tome and Principe",
      label: "Sao Tome and Principe",
      value: "Sao Tome and Principe",
    },
    {key: "Saudi Arabia", label: "Saudi Arabia", value: "Saudi Arabia"},
    {key: "Senegal", label: "Senegal", value: "Senegal"},
    {key: "Serbia", label: "Serbia", value: "Serbia"},
    {key: "Seychelles", label: "Seychelles", value: "Seychelles"},
    {key: "Sierra Leone", label: "Sierra Leone", value: "Sierra Leone"},
    {key: "Singapore", label: "Singapore", value: "Singapore"},
    {key: "Slovakia", label: "Slovakia", value: "Slovakia"},
    {key: "Slovenia", label: "Slovenia", value: "Slovenia"},
    {
      key: "Solomon Islands",
      label: "Solomon Islands",
      value: "Solomon Islands",
    },
    {key: "Somalia", label: "Somalia", value: "Somalia"},
    {key: "South Africa", label: "South Africa", value: "South Africa"},
    {
      key: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
    },
    {key: "South Sudan", label: "South Sudan", value: "South Sudan"},
    {key: "Spain", label: "Spain", value: "Spain"},
    {key: "Sri Lanka", label: "Sri Lanka", value: "Sri Lanka"},
    {key: "Sudan", label: "Sudan", value: "Sudan"},
    {key: "Suriname", label: "Suriname", value: "Suriname"},
    {
      key: "Svalbard and Jan Mayen Islands",
      label: "Svalbard and Jan Mayen Islands",
      value: "Svalbard and Jan Mayen Islands",
    },
    {key: "Swaziland", label: "Swaziland", value: "Swaziland"},
    {key: "Sweden", label: "Sweden", value: "Sweden"},
    {key: "Switzerland", label: "Switzerland", value: "Switzerland"},
    {
      key: "Syrian Arab Republic (Syria)",
      label: "Syrian Arab Republic (Syria)",
      value: "Syrian Arab Republic (Syria)",
    },
    {key: "Taiwan", label: "Taiwan", value: "Taiwan"},
    {key: "Tajikistan", label: "Tajikistan", value: "Tajikistan"},
    {key: "Tanzania", label: "Tanzania", value: "Tanzania"},
    {key: "Thailand", label: "Thailand", value: "Thailand"},
    {key: "Timor-Leste", label: "Timor-Leste", value: "Timor-Leste"},
    {key: "Togo", label: "Togo", value: "Togo"},
    {key: "Tokelau", label: "Tokelau", value: "Tokelau"},
    {key: "Tonga", label: "Tonga", value: "Tonga"},
    {
      key: "Trinidad and Tobago",
      label: "Trinidad and Tobago",
      value: "Trinidad and Tobago",
    },
    {key: "Tunisia", label: "Tunisia", value: "Tunisia"},
    {key: "Turkey", label: "Turkey", value: "Turkey"},
    {key: "Turkmenistan", label: "Turkmenistan", value: "Turkmenistan"},
    {
      key: "Turks and Caicos Islands",
      label: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
    },
    {key: "Tuvalu", label: "Tuvalu", value: "Tuvalu"},
    {key: "Uganda", label: "Uganda", value: "Uganda"},
    {key: "Ukraine", label: "Ukraine", value: "Ukraine"},
    {
      key: "United Arab Emirates",
      label: "United Arab Emirates",
      value: "United Arab Emirates",
    },
    {key: "United Kingdom", label: "United Kingdom", value: "United Kingdom"},
    {key: "USA", label: "USA", value: "USA"},
    {
      key: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
      value: "United States Minor Outlying Islands",
    },
    {key: "Uruguay", label: "Uruguay", value: "Uruguay"},
    {key: "Uzbekistan", label: "Uzbekistan", value: "Uzbekistan"},
    {key: "Vanuatu", label: "Vanuatu", value: "Vanuatu"},
    {key: "Venezuela", label: "Venezuela", value: "Venezuela"},
    {key: "Vietnam", label: "Vietnam", value: "Vietnam"},
    {
      key: "Virgin Islands, US",
      label: "Virgin Islands, US",
      value: "Virgin Islands, US",
    },
    {
      key: "Wallis and Futuna Islands",
      label: "Wallis and Futuna Islands",
      value: "Wallis and Futuna Islands",
    },
    {key: "Western Sahara", label: "Western Sahara", value: "Western Sahara"},
    {key: "Yemen", label: "Yemen", value: "Yemen"},
    {key: "Zambia", label: "Zambia", value: "Zambia"},
    {key: "Zimbabwe", label: "Zimbabwe", value: "Zimbabwe"},
    {key: "Kosovo", label: "Kosovo", value: "Kosovo"},
    {key: "Scotland", label: "Scotland", value: "Scotland"},
    {key: "Wales", label: "Wales", value: "Wales"},
    {
      key: "Saint Vincent And The Grenadines",
      label: "Saint Vincent And The Grenadines",
      value: "Saint Vincent And The Grenadines",
    },
  ],
  "Fulfillment latency for shipping": [
    "on-demand (< 30mins)",
    "Same day (>2 hour)",
    "Next day ",
    "2 days",
    "Up to 1 week",
    "More than 1 week",
    "Built to order (1 week)",
    "Built to order (2 weeks)",
    "Built to order (1 month)",
    "To be quoted upon request",
  ],
  unit_count_type: [
    {key: "Set", label: "Set", value: "Set"},
    {key: "Unit", label: "Unit", value: "Unit"},
    {key: "Count", label: "Count", value: "Count"},
  ],
  item_width_unit_of_measure: [
    {key: "angstrom", label: "Angstrom", value: "Angstrom"},
    {
      key: "millimeter_(mm)",
      label: "Millimeter (MM)",
      value: "Millimeter (MM)",
    },
    {key: "yards", label: "Yards", value: "Yards"},
    {key: "picometre", label: "Picometre", value: "Picometre"},
    {key: "miles", label: "Miles", value: "Miles"},
    {key: "decimetres", label: "Decimetres", value: "Decimetres"},
    {key: "meter_(m)", label: "Meter (M)", value: "Meter (M)"},
    {key: "inch_(in)", label: "Inch (IN)", value: "Inch (IN)"},
    {key: "feet_(ft)", label: "Feet (FT)", value: "Feet (FT)"},
    {
      key: "centimeter_(cm)",
      label: "Centimeter (CM)",
      value: "Centimeter (CM)",
    },
    {
      key: "hundredths-inches",
      label: "Hundredths-Inches",
      value: "Hundredths-Inches",
    },
    {key: "nanometre", label: "Nanometre", value: "Nanometre"},
    {key: "micron", label: "Micron", value: "Micron"},
    {key: "kilometres", label: "Kilometres", value: "Kilometres"},
  ],
  item_length_unit_of_measure: [
    {key: "angstrom", label: "Angstrom", value: "Angstrom"},
    {
      key: "millimeter_(mm)",
      label: "Millimeter (MM)",
      value: "Millimeter (MM)",
    },
    {key: "yards", label: "Yards", value: "Yards"},
    {key: "picometre", label: "Picometre", value: "Picometre"},
    {key: "miles", label: "Miles", value: "Miles"},
    {key: "decimetres", label: "Decimetres", value: "Decimetres"},
    {key: "meter_(m)", label: "Meter (M)", value: "Meter (M)"},
    {key: "inch_(in)", label: "Inch (IN)", value: "Inch (IN)"},
    {key: "feet_(ft)", label: "Feet (FT)", value: "Feet (FT)"},
    {
      key: "centimeter_(cm)",
      label: "Centimeter (CM)",
      value: "Centimeter (CM)",
    },
    {
      key: "hundredths-inches",
      label: "Hundredths-Inches",
      value: "Hundredths-Inches",
    },
    {key: "nanometre", label: "Nanometre", value: "Nanometre"},
    {key: "micron", label: "Micron", value: "Micron"},
    {key: "kilometres", label: "Kilometres", value: "Kilometres"},
  ],
  item_height_unit_of_measure: [
    {key: "angstrom", label: "Angstrom", value: "Angstrom"},
    {
      key: "millimeter_(mm)",
      label: "Millimeter (MM)",
      value: "Millimeter (MM)",
    },
    {key: "yards", label: "Yards", value: "Yards"},
    {key: "picometre", label: "Picometre", value: "Picometre"},
    {key: "miles", label: "Miles", value: "Miles"},
    {key: "decimetres", label: "Decimetres", value: "Decimetres"},
    {key: "meter_(m)", label: "Meter (M)", value: "Meter (M)"},
    {key: "inch_(in)", label: "Inch (IN)", value: "Inch (IN)"},
    {key: "feet_(ft)", label: "Feet (FT)", value: "Feet (FT)"},
    {
      key: "centimeter_(cm)",
      label: "Centimeter (CM)",
      value: "Centimeter (CM)",
    },
    {
      key: "hundredths-inches",
      label: "Hundredths-Inches",
      value: "Hundredths-Inches",
    },
    {key: "nanometre", label: "Nanometre", value: "Nanometre"},
    {key: "micron", label: "Micron", value: "Micron"},
    {key: "kilometres", label: "Kilometres", value: "Kilometres"},
  ],
  conditon_type: [
    {key: "New", label: "New", value: "New"},
    {
      key: "Used - like new",
      label: "Used - like new",
      value: "Used - like new",
    },
    {
      key: "Used - very good",
      label: "Used - very good",
      value: "Used - very good",
    },
    {key: "Used - good", label: "Used - good", value: "Used - good"},
    {
      key: "Used - acceptable",
      label: "Used - acceptable",
      value: "Used - acceptable",
    },
  ],
  currency: [
    {key: "INR", label: "Indian Rupee (INR)", value: "INR"},
    {key: "MXN", label: "Mexican Peso (MXN)", value: "MXN"},
    {key: "EUR", label: "Euro (EUR)", value: "EUR"},
    {key: "GBP", label: "British Pound (GBP)", value: "GBP"},
    {key: "USD", label: "United States Dollar (USD)", value: "USD"},
    {key: "CAD", label: "Canadian Dollar (CAD)", value: "CAD"},
    {key: "JPY", label: "Japanese Yen (JPY)", value: "JPY"},
    {key: "CNY", label: "Chinese Yuan (CNY)", value: "CNY"},
  ],
  variation_type: [
    {key: "parent_only", label: "Parent only", value: "Parent only"},
    {key: "variant", label: "Variant", value: "Variant"},
    {
      key: "parent_with_variant/s",
      label: "Parent with variant/s",
      value: "Parent with variant/s",
    },
  ],
  power_source_type: [
    {key: "AC", label: "AC", value: "AC"},
    {key: "AC/DC", label: "AC/DC", value: "AC/DC"},
    {key: "Air powered", label: "Air powered", value: "Air powered"},
    {
      key: "Battery powered",
      label: "Battery powered",
      value: "Battery powered",
    },
    {
      key: "Corded electric",
      label: "Corded electric",
      value: "Corded electric",
    },
    {key: "Crank", label: "Crank", value: "Crank"},
    {key: "DC", label: "DC", value: "DC"},
    {key: "Gas powered", label: "Gas powered", value: "Gas powered"},
    {key: "Hand powered", label: "Hand powered", value: "Hand powered"},
    {
      key: "Hydraulic powered",
      label: "Hydraulic powered",
      value: "Hydraulic powered",
    },
    {key: "Solar", label: "Solar", value: "Solar"},
    {key: "Fuel powered", label: "Fuel powered", value: "Fuel powered"},
    {key: "Solar powered", label: "Solar powered", value: "Solar powered"},
    {key: "Manual", label: "Manual", value: "Manual"},
  ],
  included_components: [
    {
      key: "30 minute charger",
      label: "30 minute charger",
      value: "30 minute charger",
    },
    {key: "Adapter", label: "Adapter", value: "Adapter"},
    {key: "Bag", label: "Bag", value: "Bag"},
    {key: "Batteries", label: "Batteries", value: "Batteries"},
    {key: "Blade", label: "Blade", value: "Blade"},
    {key: "Block", label: "Block", value: "Block"},
    {key: "Bolts", label: "Bolts", value: "Bolts"},
    {key: "Caps", label: "Caps", value: "Caps"},
    {key: "Caribineer", label: "Caribineer", value: "Caribineer"},
    {key: "Case", label: "Case", value: "Case"},
    {key: "Chain", label: "Chain", value: "Chain"},
    {key: "Charger", label: "Charger", value: "Charger"},
    {
      key: "Circular saw blade",
      label: "Circular saw blade",
      value: "Circular saw blade",
    },
    {
      key: "Comes as a combo set",
      label: "Comes as a combo set",
      value: "Comes as a combo set",
    },
    {
      key: "Compartments included",
      label: "Compartments included",
      value: "Compartments included",
    },
    {key: "Coupling", label: "Coupling", value: "Coupling"},
    {key: "Depth rod", label: "Depth rod", value: "Depth rod"},
    {key: "Detector", label: "Detector", value: "Detector"},
    {key: "Dowel", label: "Dowel", value: "Dowel"},
    {key: "Drill bits", label: "Drill bits", value: "Drill bits"},
    {
      key: "Drywall nosepeice",
      label: "Drywall nosepeice",
      value: "Drywall nosepeice",
    },
    {key: "Fuse", label: "Fuse", value: "Fuse"},
    {key: "Gauge", label: "Gauge", value: "Gauge"},
    {key: "Hammer", label: "Hammer", value: "Hammer"},
    {key: "Hose", label: "Hose", value: "Hose"},
    {key: "Light", label: "Light", value: "Light"},
    {key: "Motor", label: "Motor", value: "Motor"},
    {key: "Nails", label: "Nails", value: "Nails"},
    {key: "Nuts", label: "Nuts", value: "Nuts"},
    {
      key: "One hour charger",
      label: "One hour charger",
      value: "One hour charger",
    },
    {key: "Pads", label: "Pads", value: "Pads"},
    {key: "Pins", label: "Pins", value: "Pins"},
    {key: "Pocket clips", label: "Pocket clips", value: "Pocket clips"},
    {key: "Pump", label: "Pump", value: "Pump"},
    {
      key: "Quick connect fixtures",
      label: "Quick connect fixtures",
      value: "Quick connect fixtures",
    },
    {
      key: "Reciprocating saw blade",
      label: "Reciprocating saw blade",
      value: "Reciprocating saw blade",
    },
    {
      key: "Screwdriver bits",
      label: "Screwdriver bits",
      value: "Screwdriver bits",
    },
    {key: "Screws", label: "Screws", value: "Screws"},
    {
      key: "Shade protector",
      label: "Shade protector",
      value: "Shade protector",
    },
    {key: "Side handle", label: "Side handle", value: "Side handle"},
    {key: "Staples", label: "Staples", value: "Staples"},
    {
      key: "Surge protection",
      label: "Surge protection",
      value: "Surge protection",
    },
    {key: "Switch", label: "Switch", value: "Switch"},
    {key: "TEKS nosepiece", label: "TEKS nosepiece", value: "TEKS nosepiece"},
    {key: "Tacks", label: "Tacks", value: "Tacks"},
    {key: "Valve", label: "Valve", value: "Valve"},
    {key: "Washers", label: "Washers", value: "Washers"},
    {
      key: "Batteries included",
      label: "Batteries included",
      value: "Batteries included",
    },
    {key: "Bulb included", label: "Bulb included", value: "Bulb included"},
    {
      key: "Fan blade included",
      label: "Fan blade included",
      value: "Fan blade included",
    },
    {key: "Fuse", label: "Fuse", value: "Fuse"},
    {
      key: "Light kit included",
      label: "Light kit included",
      value: "Light kit included",
    },
    {key: "Shade included", label: "Shade included", value: "Shade included"},
    {
      key: "Shade protector",
      label: "Shade protector",
      value: "Shade protector",
    },
    {
      key: "Surge protection",
      label: "Surge protection",
      value: "Surge protection",
    },
    {key: "Switch", label: "Switch", value: "Switch"},
    {
      key: "Built-in water filter",
      label: "Built-in water filter",
      value: "Built-in water filter",
    },
    {key: "Toilet bowl", label: "Toilet bowl", value: "Toilet bowl"},
    {key: "Toilet tank", label: "Toilet tank", value: "Toilet tank"},
    {key: "Caribineer", label: "Caribineer", value: "Caribineer"},
    {key: "Handles", label: "Handles", value: "Handles"},
    {
      key: "Includes sidespray",
      label: "Includes sidespray",
      value: "Includes sidespray",
    },
  ],
  "Update delete": ["Update", "Partial update", "Delete"],
  "Short product description": [
    "Metal drill HSS-TiN",
    "Metal drill HHS-G",
    "Concrete drill",
    "Auger bits",
    "Spade",
    "Torx",
    "Inner square screws",
    "Depth stops",
    "Forstner bit",
    "Diamond drill",
    "Countersink drill bit",
    "Metal drill HHS-R",
    "Formwork and installation drill bits",
    "Phillips head screws",
    "Hexagon socket screws",
    "Flexible shafts",
    "Masonary drill bit",
    "Art drill",
    "Screwdriver bit sets",
    "Hinge hole drill",
    "Cross head screws",
    "Tile bit",
    "Crosspoint screws",
    "Wood drill",
    "Multi purpose drill",
    "Connectors",
    "Hammer drill",
    "Level",
    "Socket",
    "Universal holder",
    "Screwdriver bits",
    "Other",
  ],
  pattern_name: [
    {key: "a", label: "A", value: "A"},
    {key: "b", label: "B", value: "B"},
    {key: "c", label: "C", value: "C"},
    {key: "d", label: "D", value: "D"},
    {key: "e", label: "E", value: "E"},
    {key: "f", label: "F", value: "F"},
    {key: "g", label: "G", value: "G"},
    {key: "h", label: "H", value: "H"},
    {key: "i", label: "I", value: "I"},
    {key: "j", label: "J", value: "J"},
    {key: "k", label: "K", value: "K"},
    {key: "l", label: "L", value: "L"},
    {key: "m", label: "M", value: "M"},
    {key: "n", label: "N", value: "N"},
    {key: "o", label: "O", value: "O"},
    {key: "p", label: "P", value: "P"},
    {key: "q", label: "Q", value: "Q"},
    {key: "r", label: "R", value: "R"},
    {key: "s", label: "S", value: "S"},
    {key: "t", label: "T", value: "T"},
    {key: "u", label: "U", value: "U"},
    {key: "v", label: "V", value: "V"},
    {key: "w", label: "W", value: "W"},
    {key: "x", label: "X", value: "X"},
    {key: "y", label: "Y", value: "Y"},
    {key: "z", label: "Z", value: "Z"},
    {key: "0", label: "0", value: "0"},
    {key: "1", label: "1", value: "1"},
    {key: "2", label: "2", value: "2"},
    {key: "3", label: "3", value: "3"},
    {key: "4", label: "4", value: "4"},
    {key: "5", label: "5", value: "5"},
    {key: "6", label: "6", value: "6"},
    {key: "7", label: "7", value: "7"},
    {key: "8", label: "8", value: "8"},
    {key: "9", label: "9", value: "9"},
  ],
};

